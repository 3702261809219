import React from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const HeaderStyled = styled.header`
  background-color: #1a1a1a;
  padding: 0 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
  
  .left {
    display: flex;
    align-items: center;
    
    .logo-container {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
    
    .site-title {
      font-size: 1.8rem;
      font-weight: 600;
      font-family: Georgia, Garamond, serif;
      color: #ffffff;
    }
  }
  
  .right {
    display: flex;
    gap: 20px;
    margin-right: 20px;
    
    a {
      color: #ffffff;
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 200;
      font-family: 'Times New Roman', Georgia, Garamond, serif;
      transition: color 0.3s;
      padding: 5px 10px;
      border-radius: 4px;
      
      &:hover {
        color: #007bff;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

const Header = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('로그아웃 중 오류가 발생했습니다:', error);
    }
  };

  return (
    <HeaderStyled>
      <div className="left">
        <div className="logo-container">
          <theme.Icon.logo />
        </div>
        <span className="site-title">너드폭스 게임즈</span>
      </div>
      <div className="right">
        <a href="/">Home</a>
        <a href="/games">Games</a>
        <a href="/private">Game Terms Policy</a>
      </div>
    </HeaderStyled>
  );
};

export default Header;
