const lightTheme = {
  color: {
    background: '#ffffff',
    primary: '#333eaf',
    secondary: '#9c27b0',
    accent: '#f39c12',
    warning: '#e74c3c',
    success: '#2ecc71',
    info: '#3498db',
    error: '#e74c3c',
    disabled: '#cccccc',
    hover: '#f0f0f0',
    active: '#d0d0d0',
    placeholder: '#bfbfbf',
    focused: '#333eaf',
    textColor: '#404040',
    textColorLight: '#808080',
    textColorDark: '#000000',
  },
  fontFamily: 'Pretendard, sans-serif',
  fontWeight: {
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  fontSize: {
    base: '1rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeight: {
    base: '1.5',
    xs: '1.1',
    sm: '1.2',
    md: '1.4',
    lg: '1.6',
    xl: '1.8',
  },
  letterSpacing: {
    base: '0.01em',
  },
  border: {
    color: '#bfbfbf',
    radius: '0.25rem',
  },
  gutter: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  divider: {
    color: '#BFBFBF',
    borderColor: '#E5E5E5',
  },
  breakpoint: {
    xs: '0',
    sm: '440px',
    md: '600px',
    lg: '900px',
    xl: '1200px',
    xxl: '1536px',
  },
  spacing: {
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
  },
  opacity: {
    1: '0.1',
    2: '0.2',
    3: '0.3',
    4: '0.4',
    5: '0.5',
  },
  transition: {
    duration: '0.3s',
    timingFunction: 'ease-in-out',
  },
  shadow: {
    sm: '0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05)',
    md: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)',
    lg: '0 0.5rem 1rem rgba(0, 0, 0, 0.05)',
  },
  zIndex: {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    5: '500',
  },
}

export const Theme = {
  ...lightTheme,
}

export const Config = {
  Button: {
    variant: 'contained',
    size: 'medium',
    color: 'primary',
    style: {
      base: {
        '--variant-color': Theme.color.primary,
        '--variant-contained-color': Theme.color.background,
        position: 'relative',
        fontFamily: Theme.fontFamily,
        fontWeight: Theme.fontWeight.bold,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        minWidth: '64px',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),' +
          'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),' +
          'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        '&:disabled, &[disabled], &.Button-disabled': {
          opacity: '0.3',
          pointerEvents: 'none',
          cursor: 'default',
        },
      },
      sizeSmall: {
        fontSize: Theme.fontSize.sm,
        height: '2.25rem',
      },
      sizeMedium: {
        fontSize: Theme.fontSize.md,
        height: '2.5rem',
      },
      sizeLarge: {
        fontSize: Theme.fontSize.lg,
        height: '2.75rem',
      },
      variantOutlined: {
        color: 'var(--variant-color)',
        backgroundColor: 'transparent',
        border: `1px solid var(--variant-color)`,
      },
      variantText: {
        color: 'var(--variant-color)',
        backgroundColor: 'transparent',
      },
      variantContained: {
        color: 'var(--variant-contained-color)',
        backgroundColor: 'var(--variant-color)',
      },
    },
  },
  IconButton: {
    style: {
      '--icon-button-color': Theme.color.textColorLight,
      '--icon-button-hoverBg': 'rgba(0, 0, 0, 0.08)',
      '--icon-button-size': '40px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      '-webkit-tap-highlight-color': 'transparent',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      appearance: 'none',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      '--IconButton-hoverBg': 'rgba(0, 0, 0, 0.04)',
      fontSize: '1.75rem',
      outline: '0px',
      borderWidth: '0px',
      borderStyle: 'initial',
      borderColor: 'initial',
      borderImage: 'initial',
      margin: '0px',
      textDecoration: 'none',
      flex: '0 0 auto',
      borderRadius: '50%',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      padding: '12px',
      '&:hover': {
        backgroundColor: 'var(--icon-button-hoverBg)',
      },
    }
  },
  FormControl: {
    style: {
      display: 'inline-flex',
      position: 'relative',

      '&.TextField-root': {
        verticalAlign: 'top',
      },
      '&.start': {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&.end': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'start',
      },
      '&.top': {
        flexDirection: 'column',
      },
      '&.bottom': {
        flexDirection: 'column-reverse',
      }
    }
  },
  FormLabel: {
    style: {
      //label
      '--label-font-size': Theme.fontSize.base,
      '--label-color': Theme.color.textColor,
      '--label-focused-color': Theme.color.focused,
      '--label-font-weight': Theme.fontWeight.normal,
      fontFamily: 'inherit',
      fontSize: 'var(--label-font-size)',
      color: 'var(--label-color)',
      fontWeight: 'var(--label-font-weight)',
      lineHeight: '1.4375em',
      letterSpacing: '0.00938em',
      display: 'inline-block',
      userSelect: 'none',
      pointerEvents: 'auto',
      padding: '0px',

      '&.TextField-label': {
        '--label-font-weight': Theme.fontWeight.semibold,
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 'calc(133% - 32px)',
        '&.fixed': {
          '--label-font-weight': Theme.fontWeight.normal,
          transform: 'translate(14px, 9px)',
          position: 'absolute',
          left: '0px',
          top: '0px',
          transformOrigin: 'left top',
          zIndex: '1',
          transition: 'color 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1), max-width 200ms cubic-bezier(0, 0, 0.2, 1)',
          '&.focused': {
            color: 'var(--label-focused-color)',
          },
          '&.focused, &.filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
        '&.start': {
          marginRight: '0.5rem',
        },
        '&.end': {
          marginLeft: '0.5rem',
        },
        '&.top': {
          marginBottom: '0.25rem',
        },
        '&.bottom': {
          marginTop: '0.25rem',
        },
        '&.hidden': {
          display: 'none',
        },
      },
    }
  },
  FormHelperText: {
    style: {
      '--helper-text-color': Theme.color.textColorLight,
      '--helper-text-font-size': Theme.fontSize.xs,
      color: 'var(--helper-text-color)',
      fontSize: 'var(--helper-text-font-size)',
      lineHeight: '1.66',
      margin: '0.25rem 0 0px',
      '&.error': {
        color: Theme.color.error,
      }
    }
  },
  InputBox: {
    style: {
      '--input-font-size': Theme.fontSize.base,
      '--input-text-color': Theme.color.textColor,
      fontFamily: 'inherit',
      fontSize: 'var(--input-font-size)',
      color: 'var(--input-text-color)',
      fontWeight: '400',
      lineHeight: '1.4375em',
      letterSpacing: '0.00938em',
      boxSizing: 'border-box',
      cursor: 'text',
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: Theme.border.radius,
      flexGrow: 1,
    },
    Input: {
      style: {
        '--input-placeholder-color': Theme.color.placeholder,
        font: 'inherit',
        letterSpacing: 'inherit',
        color: 'currentcolor',
        border: '0px',
        boxSizing: 'content-box',
        background: 'none',
        fontSize: 'inherit',
        height: '1.4375em',
        margin: '0px',
        '-webkit-tap-highlight-color': 'transparent',
        display: 'block',
        minWidth: '0px',
        width: '100%',
        animationName: 'mui-auto-fill-cancel',
        animationDuration: '10ms',
        padding: '8.5px 14px',
        '&::placeholder': {
          color: 'var(--input-placeholder-color)',
        },
        '&.fixed': {
          '&::placeholder': {
            opacity: '0',
          },
          '&.focused::placeholder': {
            opacity: '1',
          }
        }
      },
    },
    Fieldset: {
      style: {
        '--fieldset-border-color': Theme.border.color,
        textAlign: 'left',
        position: 'absolute',
        inset: '0px 0px 0px',
        margin: '0px',
        padding: '0px 8px',
        pointerEvents: 'none',
        borderRadius: 'inherit',
        borderStyle: 'solid',
        borderWidth: '1px',
        overflow: 'hidden',
        minWidth: '0%',
        borderColor: 'var(--fieldset-border-color)',

        '& legend': {
          float: 'unset',
          width: 'auto',
          overflow: 'hidden',
          display: 'none',
          padding: '0',
          height: '11px',
          fontSize: '0.75em',
          visibility: 'hidden',
          maxWidth: '0.01px',
          transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          whiteSpace: 'nowrap',

          '& span': {
            paddingLeft: '5px',
            paddingRight: '5px',
            display: 'inline-block',
            opacity: '0',
            visibility: 'visible',
          }
        },
        '&.fixed': {
          inset: '-5px 0px 0px',
          '& legend': {
            display: 'block',
          },
          '&.focused legend, &.filled legend': {
            maxWidth: '100%',
            transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
          }
        },
        '&.focused': {
          '--fieldset-border-color': Theme.color.focused,
          borderWidth: '2px',
        },
      },
    },
  },
  TextField: {
    variant: 'outlined',
    size: 'medium',
    validate: true,
  },
  Checkbox: {
    size: 'medium',
    color: Theme.color.primary,
    style: {
      base: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        outline: '0px',
        border: '0px',
        margin: '0px',
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        textDecoration: 'none',
        padding: '9px',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgba(144, 202, 249, 0.08)',
        }
      },
      sizeSmall: {
        fontSize: '1.25rem',
      },
      sizeMedium: {
        fontSize: '1.5rem',
      },
      sizeLarge: {
        fontSize: '1.75rem',
      }
    },
    Input: {
      style: {
        cursor: 'inherit',
        position: 'absolute',
        opacity: '0',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        margin: '0',
        padding: '0',
        zIndex: '1',
      }
    },
    Svg: {
      style: {
        userSelect: 'none',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        flexShrink: '0',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fill: 'currentColor',
        fontSize: 'inherit',
      }
    }
  },
  Divider: {
    style: {
      borderTop: '1px solid var(--divider-border-color)',
      '&.Divider-horizontal': {
        margin: '0px',
        flexShrink: '0',
        display: 'flex',
        borderWidth: '0',

        '& span': {
          color: Theme.divider.color,
          display: 'inline-block',
          paddingLeft: 'calc(9.6px)',
          paddingRight: 'calc(9.6px)',
          whiteSpace: 'nowrap',
        },
        '&::before, &::after': {
          content: '""',
          width: '100%',
          borderTopWidth: 'thin',
          borderTopColor: Theme.divider.borderColor,
          borderTopStyle: 'inherit',
          alignSelf: 'center',
        }
      },
    }
  }
}

export const Styles = {
  Button: {
    base: Config.Button.style.base,
    sizeSmall: Config.Button.style.sizeSmall,
    sizeMedium: Config.Button.style.sizeMedium,
    sizeLarge: Config.Button.style.sizeLarge,
    variantOutlined: Config.Button.style.variantOutlined,
    variantText: Config.Button.style.variantText,
    variantContained: Config.Button.style.variantContained,
  },
  IconButton: Config.IconButton.style,
  FormControl: Config.FormControl.style,
  FormLabel: Config.FormLabel.style,
  FormHelperText: Config.FormHelperText.style,
  InputBox: {
    root: Config.InputBox.style,
    Input: Config.InputBox.Input.style,
    Fieldset: Config.InputBox.Fieldset.style,
  },
  Checkbox: {
    base: Config.Checkbox.style.base,
    sizeSmall: Config.Checkbox.style.sizeSmall,
    sizeMedium: Config.Checkbox.style.sizeMedium,
    sizeLarge: Config.Checkbox.style.sizeLarge,
    Input: Config.Checkbox.Input.style,
    Svg: Config.Checkbox.Svg.style,
  },
  Divider: Config.Divider.style,
}

export default Config;



/* type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

interface ConfigObject {
  theme?: DeepPartial<typeof Theme>;
  config?: DeepPartial<typeof Config>;
  styles?: DeepPartial<typeof Styles>;
}

function deepMerge<T extends object>(target: T, source: DeepPartial<T>): T {
  const result = { ...target };
  
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      const sourceValue = source[key];
      const targetValue = target[key];
      
      if (sourceValue && typeof sourceValue === 'object' && targetValue && typeof targetValue === 'object') {
        result[key] = deepMerge(targetValue, sourceValue as DeepPartial<typeof targetValue>);
      } else if (sourceValue !== undefined) {
        result[key] = sourceValue as T[typeof key];
      }
    }
  }
  
  return result;
}

export const defineConfig = (object: ConfigObject) => {
  if (object.theme) Object.assign(Theme, deepMerge(Theme, object.theme));
  if (object.config) Object.assign(Config, deepMerge(Config, object.config));
  if (object.styles) Object.assign(Styles, deepMerge(Styles, object.styles));
} */