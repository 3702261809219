import AppRoutes from '@/routes/AppRoutes';
import '@/styles/App.scss';
import GlobalStyle from "@/styles/GlobalStyle.ts";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <AppRoutes />
    </>
  );
};

export default App;
