import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { StackProps, styledProps } from '../types';
import { getGutterStyles, getSpacingStyles } from '../utils';

const StyledComponent = styled.div<styledProps>`
  ${props => props.$style}
  ${props => props.$sx}
`;

const Stack = React.memo(React.forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const {
    component: Component = 'div',
    children,
    sx,
    direction = 'column',
    divider,
    gutter,
    gutterX = gutter ? gutter : '',
    gutterY = gutter ? gutter : '',
    disableGutters = false,
    spacing,
    ...other
  } = props;

  // 자식 요소들 사이에 구분선 추가
  const childrenWithDivider = React.Children.toArray(children).reduce(
    (acc: React.ReactNode[], child: React.ReactNode, index: number) => {
      if (index !== 0 && divider) {
        acc.push(
          React.cloneElement(divider as React.ReactElement, {
            key: `divider-${index}`,
          })
        );
      }
      acc.push(child);
      return acc;
    },
    []
  );

  // 최종 스타일 병합
  const rootStyles = React.useMemo(() => ({
    display: 'flex',
    flexDirection: direction,
    ...(!disableGutters && getGutterStyles(gutterX, gutterY)),
    ...(spacing && getSpacingStyles(spacing, Theme)),
  }), [direction, disableGutters, gutterX, gutterY, spacing]);

  return (
    <StyledComponent
      as={Component}
      $style={rootStyles}
      $sx={sx}
      ref={ref}
      {...other}
    >
      {divider ? childrenWithDivider : children}
    </StyledComponent>
  );
}));

Stack.displayName = 'Stack';

export default Stack;

