import { FormEvent, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Stack, Checkbox, Box, IconButton, Icon, Divider } from '@/components';
import TermsDetail from './termsDetail';

const agrees = [
  {
    label: '전체동의',
    name: 'allAgree',
    required: true,
  },
  {
    label: 'CRB 이용약관 동의',
    name: 'cbrAgreement',
    required: true,
    memo: '약관내용'
  },
  {
    label: '전자금융거래 이용약관 동의',
    name: 'electronicFinancialAgreement',
    required: true,
    memo: '약관내용'
  },
  {
    label: '개인정보 수집이용 동의',
    name: 'personalInformationCollectionAgreement',
    required: true,
    memo: '약관내용'
  },
  {
    label: '광고성 정보 수신 동의',
    name: 'advertisingInformationReceiptAgreement',
    required: false,
    memo: '약관내용'
  }
]

interface FormDataType {
  [key: string]: boolean;
}

// 약관동의 페이지
const TermsOfAgreement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const requestParam = location.state?.requestParam;
  const returnPath = location.state?.returnPath;

  // agrees의 name을 key로 하는 객체 생성
  const initialFormData: FormDataType = agrees.reduce((acc, agree) => ({
    ...acc,
    [agree.name]: false
  }), {});

  // 폼 제출 가능 여부
  const [isComplete, setIsComplete] = useState(false);
  const [formData, setFormData] = useState<FormDataType>(initialFormData);

  // Layer를 위한 상태 추가
  const [selectedAgree, setSelectedAgree] = useState<typeof agrees[number] | null>(null);
  const [isVisible, setIsVisible] = useState(false);  // 페이드인 효과를 위한 상태 추가

  const handleAllAgree = (checked: boolean) => {
    const newFormData = agrees.reduce((acc, agree) => ({
      ...acc,
      [agree.name]: checked
    }), {});
    setFormData(newFormData);
    checkComplete(newFormData);
  }

  const handleChange = (name: string, checked: boolean) => {
    const newFormData = { ...formData, [name]: checked };

    const allChecked = agrees.slice(1).every(agree => newFormData[agree.name]);

    // 전체 동의 상태 업데이트
    newFormData.allAgree = allChecked;

    setFormData(newFormData);
    checkComplete(newFormData);
  }

  const checkComplete = (currentFormData: typeof formData = formData) => {
    // 필수 약관만 체크
    const isValid = agrees.every((agree) => {
      if (!agree.required || agree.name === 'allAgree') return true;
      return currentFormData[agree.name];
    });
    setIsComplete(isValid);
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      //TODO 여기에 회원가입 처리 로직 추가

      const newFormData = { ...requestParam, ...formData };
      console.log(newFormData);
      navigate('/signupSuccess', {
        state: {
          requestParam: newFormData,
          returnPath: location.pathname,
        }
      });
    } catch (error) {
      console.error('페이지 이동 실패:', error);
    }
  }

  const openAgreement = (agree: typeof agrees[number]) => {
    setSelectedAgree(agree);
    // 약간의 지연 후 페이드인 효과 적용
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  }

  const closeAgreement = () => {
    setIsVisible(false);
    // 애니메이션이 완료된 후 컴포넌트 제거
    setTimeout(() => {
      setSelectedAgree(null);
    }, 300); // transition 시간과 동일하게 설정
  }

  return (
    <>
      <Container
        className='termsOfAgreementContainer'
      >

        <Container
          component="main"
          gutter='md'
          sx={{
            paddingBottom: '6rem',
          }}
        >
          <form id="terms-of-agreement-form" onSubmit={handleSubmit}>
            <Box sx={{ height: '3rem', margin: '0 -0.6125rem' }}>
              <Checkbox
                label={agrees[0].label}
                name={agrees[0].name}
                checked={formData[agrees[0].name as keyof typeof formData]}
                indeterminate={isComplete}
                onChange={(checked) => handleAllAgree(checked)}
                sx={{
                  '& label': {
                    fontWeight: 'bold',
                  }
                }}
              />
            </Box>
            <Divider />
            <Stack sx={{ margin: '0 -0.6125rem' }}>
              {agrees.map((agree, index) => {
                if (index === 0) return null;
                return (
                  <Stack
                    direction='row'
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '3rem'
                    }}
                    key={agree.name}
                  >
                    <Checkbox
                      label={`(${agree.required ? '필수' : '선택'}) ${agree.label}`}
                      name={agree.name}
                      checked={formData[agree.name as keyof typeof formData]}
                      readOnly={!formData[agree.name as keyof typeof formData]}
                      onChange={(checked) => handleChange(agree.name, checked)}
                    />
                    <IconButton onClick={() => openAgreement(agree)}>
                      <Icon>arrow-right</Icon>
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </form>
        </Container>

          <Button
            type="submit"
            form="terms-of-agreement-form"
            fullWidth
            disabled={!isComplete}
            size='large'
            sx={{
              height: '4.5rem !important',
            }}
          >
            가입하기
          </Button>

      </Container >

      {/* 상세 약관 Layer */}
      {selectedAgree && (
        <TermsDetail
          title={selectedAgree.label}
          memo={selectedAgree.memo}
          onClose={closeAgreement}
          onAgree={() => handleChange(selectedAgree.name, true)}
          isVisible={isVisible}  // 페이드인 상태 전달
        />
      )}
    </>
  )
}

export default TermsOfAgreement; 