import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { ICON_SIZES, IconProps, styledProps } from '../types';
import icons from './icons';

interface SVGComponentProps {
  fill?: string;
  className?: string;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
}

const Svg = styled.svg<styledProps>`
  ${props => props.$style}
`;

const Icon = React.memo(React.forwardRef<HTMLElement, IconProps>((props, ref) => {
  const {
    children,
    onClick,
    color = 'currentColor',
    className,
    ariaLabel,
    size = 'medium',
    disabled,
    extension,
  } = props;


  if (!children || (!icons[children as keyof typeof icons] && !extension)) {
    console.warn(`Icon not found: "${children}"`);
    return null;
  }

  const IconComponent = React.lazy(async () => {
    try {
      const module = await import(`@/assets/icons/${children}.${extension}?react`);
      return { default: module.default as React.ComponentType<SVGComponentProps> };
    } catch (error) {
      console.error(`Failed to load icon: ${children}`, error);
      return {
        default: () => <span role="img" aria-label="error">⚠️</span>
      };
    }
  });

  const baseClassName = `icon-wrapper ${className}`.trim();
  const { viewBox, path } = icons[children as keyof typeof icons] || {};
  const fontSize = ICON_SIZES[size as keyof typeof ICON_SIZES] || size;
  const fillColor = color in Theme.color ? Theme.color[color as keyof typeof Theme.color] : color;

  const renderContent = () => {
    if (extension === 'svg' && IconComponent) {
      return (
        <IconComponent
          className={className}
          fill={fillColor}
          style={{ fontSize: fontSize, width: '1em', height: '1em' }}
          aria-hidden="true"
          ref={ref as any}
        />
      );
    }

    return (
      <Svg
        className={className}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        $style={{ fontSize: fontSize, width: '1em', height: '1em' }}
        aria-hidden="true"
        role="img"
        ref={ref as any}
      >
        <path d={path} fill={fillColor} />
      </Svg>
    );
  };

  return (
    <Suspense fallback={
      <span className={baseClassName} role="status" aria-label="아이콘 로딩 중">
        Loading...
      </span>
    }>
      {onClick ? (
        <button
          className={baseClassName}
          onClick={onClick}
          aria-label={ariaLabel}
          disabled={disabled}
        >
          {renderContent()}
        </button>
      ) : (renderContent())}
    </Suspense>
  );
}));

export default React.memo(Icon);