import React, { useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Stack, TextField, Button, Divider, TextFieldHandle } from '@/components';
import { useAuth, User } from '@/context/AuthContext';
import SignTitle from './SignTitle';
import { useTheme } from '../ThemeContext';
import { LoginType } from '@/components/auth/AuthProvider';
import { useCommonView } from '../Common/CommonViewProvider';

// 필요한 타입 정의
export interface LoginFormData {
  email: string;
  password: string;
}


// 상수 객체를 별도 파일로 분리 권장
const ERROR_MESSAGES = {
  INVALID_CREDENTIALS: '이메일 또는 비밀번호가 올바르지 않습니다.',
  GENERAL_ERROR: '로그인 처리 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
  SOCIAL_LOGIN_ERROR: (provider: string) => `${provider} 로그인 중 오류가 발생했습니다.`
};

// 타입을 별도 파일로 분리 권장
type SocialProvider = 'google' | 'apple';
const SOCIAL_LOGIN_OPTIONS: { provider: SocialProvider; label: string }[] = [
  { provider: 'google', label: 'Google 로그인' }
];

const Login = () => {
  const { Layout } = useTheme();
  const { $alert } = useCommonView();

  // 폼 데이터 상태 관리
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: ''
  });

  const emailRef = useRef<TextFieldHandle>(null);
  const passwordRef = useRef<TextFieldHandle>(null);

  // 로딩 상태와 에러 상태 관리
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { login, socialLogin, $navi } = useAuth();

  // handleChange 함수 최적화
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };


  // 로그인 처리 로직 개선
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // $alert({title:'로그인', content:'로그인을 시작합니다.'})

    if (isLoading) return;

    try {
      // 이메일, 비밀번호 Field 검증
      const emailValidation = emailRef.current?.validate();
      const passwordValidation = passwordRef.current?.validate()
      if (!emailValidation || !passwordValidation) {
        return;
      }

      setError(null);
      setIsLoading(true);

      const success = await login(formData.email, formData.password);
      if (success) {
        $navi('/');
        return;
      }
      setError(ERROR_MESSAGES.INVALID_CREDENTIALS);
    } catch (err) {
      console.error('Login error:', err);
      setError(ERROR_MESSAGES.GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  // 소셜 로그인 핸들러 개선
  const handleSocialLogin = async (provider: SocialProvider) => {
    if (isLoading) return;

    try {
      setError(null);
      setIsLoading(true);
      let user:User|null = null;

      if(provider === 'google'){
        user = await socialLogin(LoginType.GOOGLE);
      }else if(provider === 'apple'){
        // const success = await login(LoginType.APPLE, `${provider}Auth`, '');
      }else if(provider === 'kakao'){
        // const success = await login(LoginType.KAKAO, `${provider}Auth`, '');
      }

      if (user) {
        $navi('/');
        return;
      }
      setError(ERROR_MESSAGES.SOCIAL_LOGIN_ERROR(provider));
    } catch (err) {
      console.error(`${provider} login error:`, err);
      setError(ERROR_MESSAGES.SOCIAL_LOGIN_ERROR(provider));
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Layout.Container
        style={{ height: '100%' }}
      >
        <Layout.LoginWrap>
          {/* 로그인 타이틀 */}
          <SignTitle id="loginTitle" />

          {/* 로그인 폼 */}
          <form onSubmit={handleSubmit} className='loginForm'>
            {/* 입력 그룹 */}
            <Stack className='inputGroup' spacing={3} sx={{ marginTop: '3.125rem' }}>
              <TextField
                type="email"
                name="email"
                ref={emailRef}
                label={['이메일', 'hidden']}
                placeholder="이메일 입력"
                value={formData.email}
                validate={false}
                required
                disabled={isLoading}
                onChange={handleChange}
                aria-describedby={error ? "loginError" : undefined}
              />
              <TextField
                type="password"
                name="password"
                ref={passwordRef}
                label={['비밀번호', 'hidden']}
                placeholder="비밀번호 입력"
                value={formData.password}
                validate={false}
                required
                disabled={isLoading}
                onChange={handleChange}
                aria-describedby={error ? "loginError" : undefined}
              />
            </Stack>

            {/* 회원가입 링크 */}
            <Stack direction='row' sx={{ justifyContent: "space-between", alignItems: "center", marginTop: '0.75rem', }}>
              <StyledLink to="/signup" >회원가입</StyledLink>
              <StyledLink to="/forgotPassword">계정/비밀번호 찾기</StyledLink>
            </Stack>

            {/* 버튼 그룹 */}
            <Stack className='buttonGroup' spacing={3} sx={{ marginTop: '1.5rem', }}>
              <Button
                type="submit"
                size='large'
                loading={isLoading}
                aria-busy={isLoading}
                color='primary'>
                {isLoading ? '로그인 중...' : '로그인'}
              </Button>
              <Divider>또는</Divider>

              {/* Google 로그인 버튼만 표시 */}
              {SOCIAL_LOGIN_OPTIONS.map(({ provider, label }) => (
                <Button
                  type='button'
                  key={provider}
                  variant='outlined'
                  size='large'
                  loading={isLoading}
                  aria-busy={isLoading}
                  onClick={() => handleSocialLogin(provider)}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </form>
        </Layout.LoginWrap>

        {/* 에러 메시지 */}
        {error && (
          <ErrorMessage role="alert" aria-live="polite" id="loginError">
            {error}
          </ErrorMessage>
        )}
      </Layout.Container>
    </>
  );
};

const ErrorMessage = styled.div`
  background-color: #fff2f2;
  color: #d32f2f;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;

  &:hover {
    color: darkgreen;
  }
`;

export default Login; 