import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { ButtonProps, styledProps } from '../types';

const StyledButton = styled.button<styledProps>`
  ${Styles.Button.base as any}
  ${props => props.$style}
  ${props => props.$sx}
`;

const Button = React.memo(React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    component: Component = 'button',
    type = 'button',
    children,
    className,
    variant = Config.Button.variant || 'contained',
    size = Config.Button.size || 'medium',
    color = Config.Button.color || 'primary',
    fullWidth = false,
    loading = false,
    href,
    sx,
    ...other
  } = props;

  // 루트 클래스 이름 계산 - 성능 최적화를 위해 메모이제이션
  const rootClassName = React.useMemo(() => {
    return [
      className,
      variant && `Button-variant-${variant}`,
      size && `Button-size-${size}`,
      loading && 'Button-loading'
    ].filter(Boolean).join(' ');
  }, [className, variant, size, loading]);

  const rootStyles = React.useMemo(() => ({
    ...(color && {
      '--variant-color': color in Theme.color ? Theme.color[color as keyof typeof Theme.color] : color,
    }),
    ...(size && {
      ...(size === 'small' && Styles.Button.sizeSmall),
      ...(size === 'medium' && Styles.Button.sizeMedium),
      ...(size === 'large' && Styles.Button.sizeLarge),
    }),
    ...(variant && {
      ...(variant === 'outlined' && Styles.Button.variantOutlined),
      ...(variant === 'text' && Styles.Button.variantText),
      ...(variant === 'contained' && Styles.Button.variantContained),
    }),
    ...(fullWidth && {
      width: '100%',
      display: 'block'
    }),
    ...(loading && {
      color: 'transparent',
      pointerEvents: 'none'
    }),
  }), [color, fullWidth, loading, sx]);

  return (
    <StyledButton
      as={href ? 'a' : Component}
      type={type}
      className={rootClassName}
      ref={ref}
      href={href}
      $style={rootStyles}
      $sx={sx}
      {...other}
    >
      {children}
    </StyledButton>
  );
}));

Button.displayName = 'Button';

export default Button;
