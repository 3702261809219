import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormLabel } from '@/components';
import { Config, Styles, Theme } from '../config';
import { CheckboxProps, styledProps } from '../types';
import { getId } from '../utils';

const FormControl = styled.div<styledProps>`
  ${Styles.FormControl as any}
  ${props => props.$style}
  ${props => props.$sx}
`;

const CheckBoxBase = styled.div<styledProps>`
  ${Styles.Checkbox.base as any}
  ${props => props.$style}
`;

const Input = styled.input<styledProps>`
  ${Styles.Checkbox.Input as any}
  ${props => props.$style}
`;

const Svg = styled.svg<styledProps>`
  ${Styles.Checkbox.Svg as any}
  ${props => props.$style}
`;

const icons = {
  default: {
    viewBox: '0 0 24 24',
    path: 'm5 21c-.55 0-1.02083-.1958-1.4125-.5875s-.5875-.8625-.5875-1.4125v-14c0-.55.19583-1.02083.5875-1.4125s.8625-.5875 1.4125-.5875h14c.55 0 1.0208.19583 1.4125.5875s.5875.8625.5875 1.4125v14c0 .55-.1958 1.0208-.5875 1.4125s-.8625.5875-1.4125.5875zm0-2h14v-14h-14z'
  },
  indeterminate: {
    viewBox: '0 0 24 24',
    path: 'm7 13h10v-2h-10zm-2 8c-.55 0-1.02083-.1958-1.4125-.5875s-.5875-.8625-.5875-1.4125v-14c0-.55.19583-1.02083.5875-1.4125s.8625-.5875 1.4125-.5875h14c.55 0 1.0208.19583 1.4125.5875s.5875.8625.5875 1.4125v14c0 .55-.1958 1.0208-.5875 1.4125s-.8625.5875-1.4125.5875z'
  },
  checked: {
    viewBox: '0 0 24 24',
    path: 'm10.6 16.2 7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4zm-5.6 4.8c-.55 0-1.02083-.1958-1.4125-.5875s-.5875-.8625-.5875-1.4125v-14c0-.55.19583-1.02083.5875-1.4125s.8625-.5875 1.4125-.5875h14c.55 0 1.0208.19583 1.4125.5875s.5875.8625.5875 1.4125v14c0 .55-.1958 1.0208-.5875 1.4125s-.8625.5875-1.4125.5875z'
  }
};

const Checkbox = React.memo(React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    checked = false,
    className,
    color = Config.Checkbox.color || 'currentColor',
    disabled,
    error,
    id,
    indeterminate,
    label,
    labelPlacement = 'end',
    labelProps,
    name,
    onChange,
    onClick,
    readOnly,
    size = Config.Checkbox.size || 'medium',
    sx,
    value: propValue,   // value를 propValue로 이름 변경
    ...other
  } = props;

  // 라벨 텍스트 & 라벨 위치
  const formLableText = typeof label === 'object' ? label[0] : label;
  const formLabelPlacement = labelPlacement ? labelPlacement : typeof label === 'object' ? label[1] : 'fixed';

  // 필요한 ID들 생성
  const inputId = id || name || getId(12, 'TextField');
  const inputName = name ? name : 'inputId';
  const inputLabelId = `${inputId}-label`;

  // 내부 상태 관리
  const [value, setValue] = useState(propValue || '');
  const [isValue, setIsValue] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [oldError, setOldError] = useState(error);
  const [internalError, setInternalError] = useState<string>(error || '');

  // 실제 표시할 에러 메시지 계산
  const displayError = error || internalError;

  // 상태 클래스
  const currentStateClass = React.useMemo(() => {
    return [
      formLabelPlacement && `${formLabelPlacement}`,
      isFocused && 'focused',
      isValue && 'filled',
      displayError && 'error',
    ].filter(Boolean).join(' ');
  }, [formLabelPlacement, isFocused, isValue, displayError]);

  // 컨테이너 클래스
  const rootClassName = React.useMemo(() => {
    return [
      'checkbox-root',
      currentStateClass,
      className,
    ].filter(Boolean).join(' ');
  }, [currentStateClass, className]);

  // 컨테이너 스타일
  const rootStyles = React.useMemo(() => ({
    ...(color && {
      '--variant-color': color in Theme.color ? Theme.color[color as keyof typeof Theme.color] : color,
    }),
    ...(size && {
      ...(size === 'small' && Styles.Checkbox.sizeSmall),
      ...(size === 'medium' && Styles.Checkbox.sizeMedium),
      ...(size === 'large' && Styles.Checkbox.sizeLarge),
    }),
  }), [color, size]);

  // 외부 props 변경 시 내부 상태 업데이트
  useEffect(() => {
    setInternalChecked(checked);
  }, [checked]);

  // 내부 상태 관리
  const [internalChecked, setInternalChecked] = useState(checked || false);

  const { viewBox, path } = internalChecked ? icons.checked : indeterminate ? icons.indeterminate : icons.default;

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (readOnly) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    if (onClick) onClick(internalChecked);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) {
      e.preventDefault();
      return;
    }
    setInternalChecked(e.target.checked);
    if (onChange) onChange(e.target.checked);
  };

  return (
    <FormControl
      className={rootClassName}
      $sx={sx}
    >
      {label && (
        <FormLabel
          component="label"
          htmlFor={inputId}
          id={inputLabelId}
          className={`Checkbox-label ${currentStateClass}`}
          {...labelProps}
        >
          {formLableText}
        </FormLabel>
      )}
      <CheckBoxBase
        $style={rootStyles}
      >
        <Input
          type="checkbox"
          id={inputId}
          name={inputName}
          checked={internalChecked}
          onChange={handleChange}
          onClick={handleClick}
          readOnly={readOnly}
        />
        <Svg
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path} fill={color} />
        </Svg>
      </CheckBoxBase>
    </FormControl>
  );
}));

Checkbox.displayName = 'Checkbox';

export default Checkbox;
