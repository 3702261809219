interface RContextComponent {
    [name: string]: RContext;
}
export class RootContext {
    components:RContextComponent = {};

    addComponent(name:string, context:RContext){
        this.components[name] = context;
    }

    removeComponent(name: string) {
        delete this.components[name];
    }

    // $broadcast 메서드 추가
    $broadcast(event: string, data: any) {
        Object.keys(this.components).forEach((key) => {
            const context: RContext = this.components[key] as RContext;
            if (context?.events && context?.events[event]) {
                context.events[event](data);
            }
        });
    }
}

export class RContext {
    parent: RContext | null = null;
    props: any = null;
    methods: { [key: string]: (...args: any[]) => void } = {};
    events: { [key: string]: (data: any) => void } = {};  // 단일 콜백으로 변경
    listeners: { [key: string]: Function[] } = {};

    constructor(props?: any) {
        this.props = props;
    }

    // 여러 메서드를 동적으로 등록하는 export 메서드
    export(...methods: ((...args: any[]) => void)[]) {
        methods.forEach((method) => {
            if (method.name) {
                this.methods[method.name] = method;

                // 메서드를 RContext 인스턴스에 바로 바인딩
                (this as any)[method.name] = (...args: any[]) => {
                    return method(...args);
                };
            } else {
                console.error("Method without a name cannot be exported.");
            }
        });
    }
    // 이벤트 등록 메서드
    $on(event: string, callback: (data: any) => void) {
        this.events[event] = callback;  // 기존 콜백을 덮어씀
    }

    // 이벤트 실행 메서드
    $emit(event: string, data: any) {
        if (this.events[event]) {
            this.events[event](data);
        }
    }
    // 등록된 메서드 실행
    run(name: string, ...args: any[]) {
        if (this.methods[name]) {
            this.methods[name](...args); // 스프레드 연산자 사용
        } else {
            console.error(`Method "${name}" not found.`);
        }
    }

    addListener(event: string, callback: Function) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    removeListener(event: string, callback: Function) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
        }
    }

    emit(event: string, ...args: any[]) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => callback(...args));
        }
    }
}

export const rootContext = new RootContext();
window.ContextScope = rootContext;
window.CtxRef = rootContext.components;

