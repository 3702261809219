import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const DefaultLayout = () => {
  const { pathname } = useLocation();
  const currentTab = pathname.split('/')[1];

  return (
    <div>
      <Content><Outlet /></Content>
    </div>
  );
};


const Content = styled.main`
  overflow-y: auto;
`;

export default DefaultLayout; 