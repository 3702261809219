import React from 'react';
import { ComponentProps, styledProps } from '../types';
import styled from 'styled-components';

// 스타일 상수를 컴포넌트 외부로 분리
const TYPOGRAPHY_STYLES = {
  h1: { fontWeight: 300, fontSize: '6rem', lineHeight: '1.167', letterSpacing: '-0.01562em' },
  h2: { fontWeight: 300, fontSize: '3.75rem', lineHeight: '1.2', letterSpacing: '-0.00833em' },
  h3: { fontWeight: 400, fontSize: '3rem', lineHeight: '1.167', letterSpacing: '0em' },
  h4: { fontWeight: 400, fontSize: '2.125rem', lineHeight: '1.17', letterSpacing: '0.00735em' },
  h5: { fontWeight: 400, fontSize: '1.5rem', lineHeight: '1.334', letterSpacing: '0em' },
  h6: { fontWeight: 500, fontSize: '1.25rem', lineHeight: '1.6', letterSpacing: '0.0075em' },
  body1: { fontWeight: 400, fontSize: '1rem', lineHeight: '1.5', letterSpacing: '0.00938em' },
  body2: { fontWeight: 400, fontSize: '0.875rem', lineHeight: '1.43', letterSpacing: '0.01071em' },
} as const;

type VariantType = keyof typeof TYPOGRAPHY_STYLES | 'button' | 'caption' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2';

interface TypographyProps extends ComponentProps {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'textPrimary' | 'textSecondary' | 'textDisabled' | string;
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant?: VariantType;
  variantMapping?: Partial<Record<VariantType, keyof JSX.IntrinsicElements>>;
}

const StyledComponent = styled.p<styledProps>`
  ${props => props.$style}
  ${props => props.$sx}
`;

const Typography = React.memo(React.forwardRef<HTMLDivElement, TypographyProps>((props, ref) => {
  const {
    component: Component = 'p',
    children,
    align,
    className,
    variant = 'body1',
    variantMapping,
    color,
    gutterBottom,
    noWrap,
    paragraph,
    sx,

    ...other
  } = props;

  const rootClassName = React.useMemo(() => {
    return [
      variant && `Typography-${variant}`,
      align && `Typography-${align}`,
      color && `Typography-${color}`,
      noWrap && 'Typography-noWrap',
      gutterBottom && 'Typography-gutterBottom',
      paragraph && 'Typography-paragraph',
      className,
    ].filter(Boolean).join(' ');
  }, [className]);

  // 컨테이너 스타일
  const rootStyles = React.useMemo(() => ({
    ...(variant && TYPOGRAPHY_STYLES[variant as keyof typeof TYPOGRAPHY_STYLES]),
    ...(align && {
      textAlign: align
    }),
    ...(color && {
      color: `var(--${color}-color)`
    }),
    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    ...(gutterBottom && {
      marginBottom: '0.35em'
    }),
    ...(paragraph && {
      marginBottom: '1rem'
    }),
  }), [variant, align, color, noWrap, gutterBottom, paragraph, sx]);

  return (
    <StyledComponent
      as={(variantMapping && variantMapping[variant as keyof typeof variantMapping]) || Component}
      ref={ref}
      className={rootClassName}
      $style={rootStyles}
      $sx={sx}
      {...other}>
      {children}
    </StyledComponent>
  );
}));

Typography.displayName = 'Typography';

export default Typography; 