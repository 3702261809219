import React from "react";
import styled from "styled-components";

interface LinearLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    direction?: "row" | "column"; // 가로(row) 또는 세로(column) 정렬
    wrap?: boolean|string; // 가로 정렬 시 자동 줄바꿈 여부
    overflow?: "scroll" | "wrap"; // 넘칠 때 스크롤 여부
    valign?: "top" | "middle" | "bottom"; // 세로정렬
    align?: "left" | "center" | "right"; // 가로정렬
    gap?: string; // 요소 간 간격 (기본값 10px)
    margin?: string; // 전체 마진 (기본값 10px, fullWidth가 true면 제거)
    fullwidth?: boolean|string; // 모든 아이템이 동일한 크기로 가득 차도록 설정
    childwidth?: "inherit" | "none" | "same"; // 추가: 자식 요소의 너비 설정
    $position?: string;
}

const LinearLayout: React.FC<LinearLayoutProps> = ({
                                                       direction = "row",
                                                       wrap = true,
                                                       overflow = "wrap",
                                                       gap = "1rem", // 10px ≈ 1rem
                                                       margin = "0",
                                                       valign = "top",
                                                       align = "left",
                                                       fullwidth = false,
                                                       childwidth = "inherit", // 기본값: inherit
                                                       children,
                                                       ...props
                                                   }) => {
    return (
        <StyledLayout
            direction={direction}
            wrap={String(wrap)}
            overflow={overflow}
            gap={gap}
            margin={fullwidth ? "0" : margin} // ✅ fullWidth가 true면 margin을 0으로 설정
            valign={valign}
            align={align}
            fullwidth={String(fullwidth)}
            childwidth={childwidth}
            {...props}
        >
            {children}
        </StyledLayout>
    );
};

const StyledLayout = styled.div<LinearLayoutProps>`
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
    overflow-x: ${({ direction, overflow }) =>
            direction === "row" && overflow === "scroll" ? "auto" : "visible"};
    gap: ${props => props.gap || '0'};
    margin: ${props => props.margin || '0'};
    width: 100%; // 부모 컨테이너의 크기를 따라감
    align-items: ${props => props.valign || 'stretch'};
    justify-content: ${props => props.align || 'flex-start'};

    & > * {
        ${({ childwidth, align }) => {
            if (childwidth === "inherit") {
                return `
                    width: auto;
                    flex: 0 0 auto;
                `;
            }
            if (childwidth === "same") {
                return `flex: 1;`;
            }
            return '';
        }}
        max-width: 100%;
    }

    /* fullwidth가 true일 때만 마지막 요소가 남은 공간을 채움 */
    ${({ fullwidth }) => fullwidth && `
        & > *:last-child {
            flex: 1;
        }
    `}
`;

export default LinearLayout;