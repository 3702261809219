import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormHelperText, FormLabel } from '@/components';
import { Config, Styles, Theme } from '../config';
import { TextFieldProps, ValidateMessage, ValidatePattern, ValidatePatternType, InputRestrictionPattern, styledProps } from '../types';
import { getId } from '../utils';

const FormControl = styled.div<styledProps>`
  ${Styles.FormControl as any}
  ${props => props.$style}
  ${props => props.$sx}
`;

const InputBox = styled.div<styledProps>`
  ${Styles.InputBox.root as any}
  ${props => props.$style}
`;

const Input = styled.input<styledProps>`
  ${Styles.InputBox.Input as any}
  ${props => props.$style}
`;

const Fieldset = styled.fieldset<styledProps>`
  ${Styles.InputBox.Fieldset as any}
  ${props => props.$style}
`;

// 외부에서 접근할 메서드들을 정의하는 인터페이스
export interface TextFieldHandle {
  validate: () => boolean;
  getValue: () => string;
  setValue: (value: string) => void;
}

const TextField = React.memo(React.forwardRef<TextFieldHandle | HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    autoComplete,
    className,
    color,
    error,
    showError,
    fullWidth,
    helperText,
    id,
    label,
    regx,
    labelPlacement,
    labelProps,
    multiline,
    name,
    onBlur,
    onChange,
    onError,
    onFocus,
    onInput,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    pattern,
    required,
    rounded,
    size = Config.TextField.size || 'medium',
    sx,
    type = 'text',
    validate = Config.TextField.validate || false,
    value: propValue,   // value를 propValue로 이름 변경
    variant = Config.TextField.variant || 'outlined',
    maxLength,
    minLength,
    ...other
  } = props;

  // Type 정의
  const inputType = ['email', 'password', 'number', 'tel', 'url', 'date', 'time', 'datetime-local', 'month', 'week', 'search', 'color', 'file', 'hidden', 'image', 'range', 'passwordSimple'].includes(type) ? type : 'text';

  // 라벨 텍스트 & 라벨 위치
  const formLableText = typeof label === 'object' ? label[0] : label;
  const formLabelPlacement = labelPlacement ? labelPlacement : typeof label === 'object' ? label[1] : 'fixed';

  // 필요한 ID들 생성
  const inputId = id || name || getId(12, 'TextField');
  const inputName = name ? name : 'inputId';
  const inputLabelId = `${inputId}-label`;
  const helperTextId = `${inputId}-helper-text`;

  // 내부 상태 관리
  const [value, setValue] = useState(propValue || '');
  const [isValue, setIsValue] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [oldError, setOldError] = useState(error);
  const [internalError, setInternalError] = useState<string>(error || '');

  // 문자 입력 헝용 정규식
  const restricRegex: string = regx != undefined?regx:InputRestrictionPattern[type as keyof typeof InputRestrictionPattern] || '';

  // 실제 표시할 에러 메시지 계산
  const displayError = error || internalError;

  // 상태 클래스
  const currentStateClass = React.useMemo(() => {
    return [
      formLabelPlacement && `${formLabelPlacement}`,
      isFocused && 'focused',
      isValue && 'filled',
      displayError && 'error',
    ].filter(Boolean).join(' ');
  }, [formLabelPlacement, isFocused, isValue, displayError]);

  // 컨테이너 클래스
  const rootClassName = React.useMemo(() => {
    return [
      'TextField-root',
      currentStateClass,
      className,
    ].filter(Boolean).join(' ');
  }, [currentStateClass, className]);

  // 컨테이너 스타일
  const rootStyles = React.useMemo(() => ({
    ...(fullWidth && { width: '100%' }),
  }), [fullWidth]);

  const fieldsetStyles = React.useMemo(() => ({
    ...(rounded && { borderRadius: rounded }),
  }), [rounded]);

  // 외부 props 변경 시 내부 상태 업데이트
  useEffect(() => {
    if (propValue !== undefined) {
      if(restricRegex && restricRegex != '')setValue(propValue.replace(new RegExp(`[^${restricRegex}]`, 'g'), ''));
      setIsValue(propValue !== '');
    }
    // error prop이 변경되면 내부 에러 상태도 업데이트
    if (!oldError && oldError !== error) {
      setInternalError(error || '');
      setOldError(error);
    }
  }, [propValue, error, restricRegex]);

  // 값 변경 시 상태 업데이트 및 한글 제거
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = restricRegex && restricRegex != '' 
      ? e.target.value.replace(new RegExp(`[^${restricRegex}]`, 'g'), '')
      : e.target.value;

    // maxLength 제한 적용
    if (maxLength !== undefined) {
      newValue = newValue.slice(0, maxLength);
    }

    setValue(newValue);
    e.target.value = newValue;
    setIsValue(newValue !== '');

    // 이미 검증된 경우 건너뜀
    if (internalError) validateValue(type, newValue);
    if (onChange) onChange(e);
  };

  // 포커스 시 포커스 상태 업데이트
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  // 키 입력 시 포커스 상태 업데이트
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e);
  };

  // 키 입력 시 문자입력 제한
  const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type && type in InputRestrictionPattern && restricRegex && restricRegex != '') {
      const char = e.key;
      const regex = new RegExp(`[${restricRegex}]`, 'g');

      if (e.key && char.length === 1 && !regex.test(char)) {
        e.preventDefault();
      }
    }

    if (onInput) onInput(e);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) onKeyUp(e);
  };

  // 포커스 손실 시 포커스 상태 업데이트
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    validateValue(type, value);
    if (onBlur) onBlur(e);
  };

  // 유효성 검증 함수 개선
  const validateValue = (validType: string, validValue: string, execute?: boolean): boolean => {
    // validate prop이 false이고 execute가 true가 아닌 경우에만 검증을 건너뜀
    if (!validate && execute !== true) return true;

    let newErrorMsg: string = '';

    // 필수 입력 필드 검사
    if (!validValue && required && execute === true) {
      newErrorMsg = ValidateMessage.required;
    }
    // 최소 길이 검사
    else if (minLength !== undefined && validValue.length < minLength) {
      newErrorMsg = `최소 ${minLength}자 이상 입력해주세요.`;
    }
    // 최대 길이 검사
    else if (maxLength !== undefined && validValue.length > maxLength) {
      newErrorMsg = `최대 ${maxLength}자까지 입력 가능합니다.`;
    }
    // 값이 있는 경우에만 패턴 검사 수행
    else if (validValue) {
      const patternToTest = pattern || restricRegex;

      if (patternToTest && patternToTest != '') {
        const regex = new RegExp(`[${restricRegex}]`, 'g');
        if (!regex.test(validValue)) {
          newErrorMsg = ValidateMessage[validType as keyof ValidatePatternType] || '올바른 형식이 아닙니다.';
        }
      }
    }

    setInternalError(newErrorMsg);

    // 에러 상태가 변경될 때마다 부모 컴포넌트에 알림
    if (onError) {
      onError({
        field: name || inputId,
        value: validValue,
        errorMessage: newErrorMsg,
        isValid: !newErrorMsg,
        type: validType
      });
    }

    return newErrorMsg ? false : true;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  React.useImperativeHandle(ref, () => ({
    validate: (validType?: string) => {
      return validateValue(validType || type, value, true);
    },
    getValue: () => value,
    setValue: (newValue: string) => {
      const sanitizedValue = restricRegex?newValue.replace(new RegExp(`[^${restricRegex}]`, 'g'), ''):newValue;
      setValue(sanitizedValue);
      if (inputRef.current) {
        inputRef.current.value = sanitizedValue;
      }
      validateValue(type, sanitizedValue, true);
    }
  }), [type, value, restricRegex, validateValue]);

  return (
    <FormControl
      className={rootClassName}
      $style={rootStyles}
      $sx={sx}
    >
      {label && (
        <FormLabel
          htmlFor={inputId}
          id={inputLabelId}
          className={`TextField-label ${currentStateClass}`}
          required={required}
          {...labelProps}
        >
          {formLableText}
        </FormLabel>
      )}
      <InputBox
        className={`TextField-inputBox ${currentStateClass}`}
      >
        <Input
          className={`TextField-input ${currentStateClass}`}
          ref={inputRef}
          required={required}
          id={inputId}
          name={inputName}
          type={inputType}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          aria-describedby={helperTextId}
          autoComplete={autoComplete}
          maxLength={maxLength}
          {...other}
        />
        <Fieldset
          className={`TextField-fieldset ${currentStateClass}`}
          $style={fieldsetStyles as any}
        >
          <legend>
            <span>{formLableText}</span>
          </legend>
        </Fieldset>
      </InputBox>

      {/* 에러 메시지 또는 도움말 텍스트 표시 */}
      {(showError && (displayError || helperText)) && (
        <FormHelperText id={helperTextId} className={`TextField-helperText ${currentStateClass}`}>
          {displayError || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}));

TextField.displayName = 'TextField';

export default TextField;
