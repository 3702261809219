import { Config, Styles, Theme } from '../config';
import { SpacingValue, Breakpoint } from '../types';

export function getGutterStyles (
  gutterX: SpacingValue | string,
  gutterY: SpacingValue | string,
) {
  // gutterX, gutterY 값이 Breakpoint인 경우 테마의 gutter 값을 사용, 
  // 문자열인 경우 직접 값을 사용
  const horizontalPadding = gutterX && (Theme.gutter[gutterX as Breakpoint] || gutterX);
  const verticalPadding = gutterY && (Theme.gutter[gutterY as Breakpoint] || gutterY);

  // 스프레드 연산자를 사용하여 조건부로 스타일 객체 생성
  return {
    ...(horizontalPadding && {
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    }),
    ...(verticalPadding && {
      paddingTop: verticalPadding,
      paddingBottom: verticalPadding,
    }),
  };
};