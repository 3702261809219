import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { ContainerProps, Breakpoint, styledProps } from '../types';
import { getGutterStyles } from '../utils';

const StyledComponent = styled.div<styledProps>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${props => props.$style}
  ${props => props.$sx}
`;

const Container = React.memo(React.forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  const {
    component: Component = 'div',
    children,
    sx,
    page,
    maxWidth,
    gutter,
    gutterX = gutter ? gutter : '',
    gutterY = gutter ? gutter : '',
    disableGutters = false,
    ...other
  } = props;


  // 최종 스타일 병합
  const rootStyles = React.useMemo(() => ({
    ...(page && {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    ...(maxWidth && {
      maxWidth: Theme.breakpoint[maxWidth as Breakpoint] || maxWidth,
    }),
    ...(!disableGutters && getGutterStyles(gutterX, gutterY)),
  }), [page, maxWidth, gutterX, gutterY, disableGutters]);

  return (
    <StyledComponent
      as={Component}
      $style={rootStyles}
      $sx={sx}
      ref={ref}
      {...other}
    >
      {children}
    </StyledComponent>
  );
}));

Container.displayName = 'Container';

export default Container;
