import React from "react";
import styled from "styled-components";

interface GridLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    columns?: number; // 전체 컬럼 개수
    gap?: string; // 간격 설정
}

interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
    height?: string; // 행의 높이
}

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
    colspan?: number; // 컬럼 병합
    rowspan?: number; // 행 병합
    width?: string; // 개별 요소 너비
    height?: string; // 개별 요소 높이
    valign?: 'top' | 'middle' | 'bottom'; // 추가
    justify?: 'start' | 'center' | 'end'; // 추가
}

const GridLayout: React.FC<GridLayoutProps> & {
    Row: React.FC<GridRowProps>;
    Item: React.FC<GridItemProps>;
} = ({ children, columns = 3, gap = "1rem", ...props }) => {
    return (
        <StyledGrid columns={columns} gap={gap} {...props}>
            {children}
        </StyledGrid>
    );
};

const GridRow: React.FC<GridRowProps> = ({ children, height = "auto", ...props }) => {
    return (
        <StyledGridRow height={height} {...props}>
            {children}
        </StyledGridRow>
    );
};

const GridItem: React.FC<GridItemProps> = ({
    children,
    colspan = 1,
    rowspan = 1,
    width = "auto",
    height = "auto",
    valign = 'top',
    justify = 'start',
    ...props
}) => {
    return (
        <StyledGridItem 
            colspan={colspan} 
            rowspan={rowspan} 
            width={width} 
            height={height}
            valign={valign}
            justify={justify}
            {...props}
        >
            {children}
        </StyledGridItem>
    );
};

const StyledGrid = styled.div<GridLayoutProps>`
    display: table;
    width: 100%;
    border-spacing: ${({ gap }) => gap};
    table-layout: fixed;
    border-collapse: collapse;
`;

const StyledGridRow = styled.div<GridRowProps>`
    display: table-row;
    height: ${({ height }) => height};
`;

const StyledGridItem = styled.div<GridItemProps & {
    align?: 'start' | 'center' | 'end';
    justify?: 'start' | 'center' | 'end';
}>`
    display: table-cell;
    width: ${({ width }) => width === 'auto' ? `${100}%` : width};
    height: ${({ height }) => height};
    vertical-align: ${({ valign }) => valign};
    text-align: ${({ justify }) => justify};
    padding: 0.5rem;

    ${({ rowspan }) => rowspan && `
        rowspan: ${rowspan};
    `}

    ${({ colspan }) => colspan && `
        colspan: ${colspan};
    `}

    ${({ rowspan }) => rowspan && `
        position: relative;
        &::after {
            content: '';
            display: table-cell;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: ${() => `calc(100% * ${rowspan})`};
        }
    `}

    > * {
        width: 100%;
        height: 100%;
    }
`;

GridLayout.Row = GridRow;
GridLayout.Item = GridItem;

export default GridLayout;