import {FormEvent, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Stack, TextField, Button} from '@/components';
import {ValidatePattern, ValidatePatternType, ValidationProps} from '@/components/common/types';
import { useTheme } from '../ThemeContext';
import SignTitle from './SignTitle';
import { useAuth } from '@/context/AuthContext';
import Popups from '../Popups/Popups';
import { IdentityVerificationDto } from '@/types/TransactionTypes';
import { useCommonView } from '../Common/CommonViewProvider';

// 회원가입 폼 인터페이스 개선
export interface SignUpForm {
  name: string;
  email: string;
  emailCode: string;
  password: string;
  passwordCheck: string;
  emailSub?: string;
  emailSubCode?: string;
}

// 회원가입 페이지
const SignUp = () => {
  const { Layout } = useTheme();
  const { $alert, $timeout, $showLoading, $hideLoading } = useCommonView();
  const { login, $navi } = useAuth();

  const { register, emailIdentityVerificationSendCode, emailIdentityCodeVerification } = useAuth();
  const navigate = useNavigate()
  const location = useLocation();
  const requestParam = location.state?.requestParam;
  const returnPath = location.state?.returnPath;

  // 폼 제출 가능 여부
  const [isComplete, setIsComplete] = useState(false);

  // 초기 상태에 인증코드 필드 추가
  const [formData, setFormData] = useState<SignUpForm>(() => {
    if (returnPath || requestParam) {
      return requestParam;
    }
    return {
      name: '',
      email: '',
      emailCode: '',
      password: '',
      passwordCheck: '',
      emailSub: '',
      emailSubCode: '',
    };
  });

  // errors 상태에 인증코드 필드 추가
  const [errors, setErrors] = useState<Record<keyof SignUpForm, string>>({
    name: '',
    email: '',
    emailCode: '',
    password: '',
    passwordCheck: '',
    emailSub: '',
    emailSubCode: '',
  });

  // 이메일 인증 상태 관리
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailSubVerified, setEmailSubVerified] = useState(false);

  // 인증코드 요청 상태 관리를 위한 상태 추가
  const [showEmailCodeInput, setShowEmailCodeInput] = useState<IdentityVerificationDto.IdentityVerificationDtoResponse>();
  const [showEmailSubCodeInput, setShowEmailSubCodeInput] = useState<IdentityVerificationDto.IdentityVerificationDtoResponse>();

  // 타이머 상태 추가
  const [emailTimer, setEmailTimer] = useState<number | null>(null);
  const [emailSubTimer, setEmailSubTimer] = useState<number | null>(null);

  // 타이머 포맷팅 함수 추가
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // returnPath가 있을 때 유효성 검사 실행
  useEffect(() => {
    if (returnPath) {
      checkComplete();
    }
  }, [returnPath]);

  // 입력값 변경 핸들러
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const newFormData = { ...prev, [name]: value };

      // isComplete가 true이거나 현재 필드에 에러가 있는 경우에만 즉시 검증
      if (isComplete || errors[name as keyof SignUpForm]) {
        validate(name, value, newFormData);
      } else {
        checkComplete(newFormData);
      }

      return newFormData;
    });
  };

  // 입력값 포커스 이탈 핸들러
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    validate(name, value);
  };

  // 에러 핸들러
  const handleError = (internalError: ValidationProps) => {
    if (!internalError || !internalError.field) return;

    // 타입 안전성 보장
    const field = internalError.field as keyof SignUpForm;
    errors[field] = internalError.errorMessage || '';
  }

  // 필드 유효성 검사 함수 개선
  const validate = (name: string, value: string, currentFormData: SignUpForm = formData) => {
    const newErrors = { ...errors };

    // 비밀번호 검사
    switch (name) {
      case 'passwordCheck':
      case 'password': {
        if (currentFormData.password.length >= 1 && currentFormData.passwordCheck.length >= 1) {
          if (currentFormData.password.length < 8) {
            newErrors.password = '비밀번호는 8자리 이상이어야 합니다.';
          } else {
            newErrors.password = '';
            newErrors.passwordCheck = currentFormData.password !== currentFormData.passwordCheck
              ? '비밀번호가 일치하지 않습니다.' : '';
          }
        }
        break;
      }
    }

    setErrors(newErrors);
    checkComplete(currentFormData);
  };

  // 폼 제출 가능 여부 검사
  const checkComplete = (currentFormData: SignUpForm = formData) => {
    const isValid = Object.entries(currentFormData).every(([key, value]) => {
      // 선택적 필드인 emailSub는 검증 제외
      if (key === 'emailSub') return true;

      // 필드명 매핑
      const validationKey = key === 'name' ? 'userNameKor' :
        key === 'passwordCheck' ? 'password' : key;

      if (validationKey === 'password') {
        const pattern = ValidatePattern.password;
        return true
      }else{
        const pattern = ValidatePattern[validationKey as keyof ValidatePatternType];
        return new RegExp(pattern).test(value);
      }
      return true;
    });

    setIsComplete(isValid);
  };

  // 이메일 인증코드 요청 핸들러 수정
  const handleRequestVerification = async (type: 'email' | 'emailSub') => {
    const email = type === 'email' ? formData.email : formData.emailSub;
    if (!email) {
      $alert({ content: '이메일을 입력해주세요.' });
      return;
    }

    try {
      $showLoading();
      const response = await emailIdentityVerificationSendCode(email);
      if (response) {
        $alert({ content: '인증코드가 발송되었습니다. 이메일을 확인하시고 인증코드를 입력해 주세요.' });
        
        if (type === 'email') {
          setShowEmailCodeInput(response);
          setEmailTimer(180);
          $timeout(180, {
            tick: (time) => setEmailTimer(time),
            complete: () => {
              $alert({ content: '인증 시간이 만료되었습니다. 인증코드를 다시 요청해주세요.' });
              setShowEmailCodeInput(undefined);
              setEmailTimer(null);
            }
          });
        } else {
          setShowEmailSubCodeInput(response);
          setEmailSubTimer(180);
          $timeout(180, {
            tick: (time) => setEmailSubTimer(time),
            complete: () => {
              $alert({ content: '인증 시간이 만료되었습니다. 인증코드를 다시 요청해주세요.' });
              setShowEmailSubCodeInput(undefined);
              setEmailSubTimer(null);
            }
          });
        }
      }
    } catch (error) {
      $alert({ content: '인증코드 발송에 실패했습니다.' });
    } finally {
      $hideLoading();
    }
  };

  // 이메일 인증코드 확인 핸들러
  const handleVerifyCode = async (type: 'email' | 'emailSub') => {
    const code = type === 'email' ? formData.emailCode : formData.emailSubCode;
    if (!code) {
      $alert({ content: '인증코드를 입력해주세요.' });
      return;
    }
    let codeInfo:IdentityVerificationDto.IdentityVerificationDtoResponse|undefined;
    let codeValue:string|undefined;
    if (type === 'email') {
      codeInfo = showEmailCodeInput;
      codeValue = formData.emailCode;
    } else {
      codeInfo = showEmailSubCodeInput;
      codeValue = formData.emailSubCode;
    }
    if(!codeInfo || !codeValue) return;

    // 인증코드 확인 API 호출
    const response = await emailIdentityCodeVerification(codeInfo?.id, codeValue);
    if (response && response.completed) {
      formData.emailCode = response.code;
      if (type === 'email') {
        setEmailVerified(true);
      } else {
        setEmailSubVerified(true);
      }
      $alert({ content: '인증이 완료되었습니다.' });
    } else {
      formData.emailCode = '';
      $alert({ content: '잘못된 인증코드입니다.' });
    }
  };

  // 폼 제출 핸들러
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isComplete) {
      $alert({ content: '모든 필수 항목을 입력해주세요.' });
      return;
    }
    
    try {
      $showLoading();
      await register({
        id: formData.email,
        password: formData.password,
        name: formData.name,
        code: formData.emailCode
      });
      
      $alert({ content: '회원가입이 완료되었습니다.' });
      
      const success = await login(formData.email, formData.password);
      if (success) {
        $navi('/');
        return;
      }

    } catch (error: unknown) {
      let errorMessage = '알 수 없는 오류가 발생했습니다.';
      let detailMessage = '';
      
      if (error instanceof Error) {
        errorMessage = error.message;
        detailMessage = '상세 오류: ' + error.message;
      } else if (typeof error === 'object' && error && 'message' in error) {
        errorMessage = String((error as { message: unknown }).message);
        detailMessage = '상세 오류: ' + errorMessage;
      }

      $alert({ 
        content: '회원가입에 실패했습니다.', 
        subcontent: errorMessage 
      });
      console.error('회원가입 실패:', detailMessage);
    } finally {
      $hideLoading();
    }
  };

  // 되돌아가기 핸들러 추가
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Layout.Container
        style={{ height: '100%' }}
      >
        <Layout.LoginWrap>
          {/* 로그인 타이틀 */}
          <SignTitle id="loginTitle" subtitle="회원가입" />
          <form className="loginForm" onSubmit={handleSubmit}>
            <Stack spacing={5}>
              <TextField
                type="userNameKor"
                name="name"
                label="이름"
                labelPlacement="top"
                placeholder="이름 입력"
                value={formData.name}
                maxLength={5}
                required
                onError={handleError}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                color='primary'
              />
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    type="email"
                    name="email"
                    label="계정"
                    labelPlacement="top"
                    placeholder="이메일 입력"
                    value={formData.email}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={handleError}
                    error={errors.email}
                    sx={{ flexGrow: 1 }}
                    disabled={emailVerified}
                  />
                  <Button
                    type="button"
                    onClick={() => handleRequestVerification('email')}
                    disabled={emailVerified || !formData.email}
                    sx={{ marginTop: '2rem', flexBasis: '6rem' }}
                  >
                    인증코드 요청
                  </Button>
                </Stack>
                {showEmailCodeInput && (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="number"
                      name="emailCode"
                      label="계정 인증코드"
                      labelPlacement="top"
                      placeholder="인증코드 입력"
                      value={formData.emailCode}
                      onChange={handleChange}
                      sx={{ flexGrow: 1 }}
                      disabled={emailVerified}
                      helperText={emailTimer ? `남은 시간: ${formatTime(emailTimer)}` : '시간이 만료되었습니다'}
                    />
                    <Button
                      type="button"
                      onClick={() => handleVerifyCode('email')}
                      disabled={emailVerified || !formData.emailCode || !emailTimer}
                      sx={{ marginTop: '2rem', flexBasis: '6rem' }}
                    >
                      {`확인(${formatTime(emailTimer || 0)})`}
                    </Button>
                  </Stack>
                )}
              </Stack>
              <Stack spacing={2}>
                <TextField
                  type="password"
                  name="password"
                  label="비밀번호"
                  regx=""
                  labelPlacement="top"
                  placeholder="비밀번호 입력 (8자리 이상)"
                  value={formData.password}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onError={handleError}
                  error={errors.password}
                />
                <TextField
                  type="password"
                  name="passwordCheck"
                  label="비밀번호 확인"
                  regx=""
                  labelPlacement="top"
                  placeholder="비밀번호 입력 (8자리 이상)"
                  value={formData.passwordCheck}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onError={handleError}
                  error={errors.passwordCheck}
                />
              </Stack>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    type="email"
                    name="emailSub"
                    label="보조 이메일(선택)"
                    labelPlacement="top"
                    placeholder="보조 이메일 입력"
                    value={formData.emailSub}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.emailSub}
                    helperText="* 계정 찾기 시 확인용으로 사용됩니다."
                    sx={{ flexGrow: 1 }}
                    disabled={emailSubVerified}
                  />
                  <Button
                    type="button"
                    onClick={() => handleRequestVerification('emailSub')}
                    disabled={emailSubVerified || !formData.emailSub}
                    sx={{ marginTop: '2rem', flexBasis: '6rem' }}
                  >
                    인증코드 요청
                  </Button>
                </Stack>
                {showEmailSubCodeInput && formData.emailSub && (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="text"
                      name="emailSubCode"
                      label="보조계정 인증코드"
                      labelPlacement="top"
                      placeholder="인증코드 입력"
                      value={formData.emailSubCode}
                      onChange={handleChange}
                      sx={{ flexGrow: 1 }}
                      disabled={emailSubVerified}
                      helperText={emailSubTimer ? `남은 시간: ${formatTime(emailSubTimer)}` : '시간이 만료되었습니다'}
                    />
                    <Button
                      type="button"
                      onClick={() => handleVerifyCode('emailSub')}
                      disabled={emailSubVerified || !formData.emailSubCode || !emailSubTimer}
                      sx={{ marginTop: '2rem', flexBasis: '6rem' }}
                    >
                      {`확인(${formatTime(emailSubTimer || 0)})`}
                    </Button>
                  </Stack>
                )}
              </Stack>
              {/* 버튼 그룹 추가 */}
              <Stack className='buttonGroup' direction="row" spacing={2} style={{ marginTop: '20px' }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={handleGoBack}
                  style={{ flex: 1 }}
                >
                  되돌아가기
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isComplete}
                  style={{ flex: 1 }}
                >
                  회원가입
                </Button>
              </Stack>
            </Stack>
          </form>
        </Layout.LoginWrap>
        <Popups />
      </Layout.Container>
    </>
  )
}

export default SignUp;