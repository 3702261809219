import { v4 as uuidv4 } from 'uuid';

/**
 * UUID 기반의 랜덤 ID를 생성하는 유틸리티 함수
 * @param length - 생성할 ID의 길이 (기본값: 10)
 * @param prefix - ID 앞에 붙일 접두사 (선택사항)
 * @returns 생성된 랜덤 ID
 */
export const getId = (length: number = 10, prefix?: string): string => {
  const uuid = uuidv4().replace(/-/g, '');
  const randomString = uuid.substring(0, length);
  return prefix ? `${prefix}_${randomString}` : randomString;
};

// 특정 용도별 ID 생성 함수들
export const GUID = {
  // 사용자 ID 생성
  user: () => getId(10, 'u'),
  
  // 주문 ID 생성
  order: () => getId(12, 'ord'),
  
  // 파일 ID 생성
  file: () => getId(8, 'f'),
  
  // 커스텀 길이와 접두사로 ID 생성
  custom: (length: number, prefix?: string) => getId(length, prefix)
}; 