import Footer from '@/pages/Footer';
import Header from '@/pages/Header';
import styled, { keyframes } from 'styled-components';
import Popups from './Popups/Popups';
import marketImage from '@/assets/images/market-width.png';
// 또는 상대 경로로 시도
import titleImg1 from '@/assets/images/market/Title_1.png';
import titleImg2 from '@/assets/images/market/Title_2.png';
import titleImg3 from '@/assets/images/market/Title_3.png';
import titleImg4 from '@/assets/images/market/Title_4.png';
import titleImg5 from '@/assets/images/market/Title_5.png';
import titleImg6 from '@/assets/images/market/Title_6.png';
import titleImg7 from '@/assets/images/market/Title_7.png';
import titleImg8 from '@/assets/images/market/Title_8.png';
import titleImg9 from '@/assets/images/market/Title_9.png';
import { useEffect, useState } from 'react';

// 페이드인/아웃 애니메이션
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const MainContentStyled = styled.div`
  margin: 0 auto;
  
  .hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: url(${marketImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    margin-bottom: 30px;
    
    .hero-content {
      position: relative;
      z-index: 2;
      padding: 40px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
    }
    
    .hero-title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #fff;
      font-weight: 700;
      margin-top: 300px;
    }
    
    .hero-subtitle {
      font-size: 1.5rem;
      margin-bottom: 30px;
      color: #aaa;
      max-width: 800px;
      line-height: 1.6;
    }
  }
  
  .features-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    
    .feature-card {
      flex: 1;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 0;
      margin: 0 15px;
      text-align: center;
      overflow: hidden;
      position: relative;
      height: 300px;
      
      .feature-image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      
      .feature-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        animation: ${fadeIn} 0.3s ease-in-out;
        opacity: 0;
        
        &.active {
          opacity: 1;
        }
        
        &.fade-out {
          animation: ${fadeOut} 0.3s ease-in-out;
        }
      }
      
      .feature-content {
        position: relative;
        z-index: 2;
        padding: 25px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.1));
      }
      
      .feature-title {
        font-size: 1.6rem;
        margin-bottom: 15px;
        color: #fff;
      }
      
      .feature-description {
        font-size: 1.2rem;
        color: #eee;
        line-height: 1.5;
      }
    }
  }
`;

const Main = (props: any) => {
    // 모든 이미지 배열 생성
    const allImages = [
        titleImg1, titleImg2, titleImg3, titleImg4, titleImg5, 
        titleImg6, titleImg7, titleImg8, titleImg9
    ];
    
    // 3개의 이미지 상태 초기화 (카드마다 다른 인덱스로 시작)
    const [imageIndex1, setImageIndex1] = useState(0);
    const [imageIndex2, setImageIndex2] = useState(3);
    const [imageIndex3, setImageIndex3] = useState(6);
    
    // 이미지 페이드인/아웃 상태
    const [fadeState1, setFadeState1] = useState('active');
    const [fadeState2, setFadeState2] = useState('active');
    const [fadeState3, setFadeState3] = useState('active');
    
    // 주기적으로 이미지 변경
    useEffect(() => {
        const changeImage = (
            currentIndex: number, 
            setIndex: React.Dispatch<React.SetStateAction<number>>, 
            setFade: React.Dispatch<React.SetStateAction<string>>
        ) => {
            setFade('fade-out');
            
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % allImages.length);
                setFade('active');
            }, 100);
        };
        
        // 각 카드마다 다른 간격으로 이미지 변경
        const timer1 = setInterval(() => {
            changeImage(imageIndex1, setImageIndex1, setFadeState1);
        }, 9000);
        
        const timer2 = setInterval(() => {
            changeImage(imageIndex2, setImageIndex2, setFadeState2);
        }, 5000);
        
        const timer3 = setInterval(() => {
            changeImage(imageIndex3, setImageIndex3, setFadeState3);
        }, 12000);
        
        return () => {
            clearInterval(timer1);
            clearInterval(timer2);
            clearInterval(timer3);
        };
    }, []);

    return (
        <>
            <div className={'main-frame'}>
                <div className={'main-container'}>
                    <Header />
                    <div className={'content-body'}>
                        <MainContentStyled>
                            <div className="hero-section">
                                <div className="hero-content">
                                    <h1 className="hero-title">디어마이프렌즈</h1>
                                    <p className="hero-subtitle">
                                        인공지능 기술로 구현된 혁신적인 연애 시뮬레이션 게임. <br></br>
                                        당신만의 특별한 사랑이야기를 시작하세요
                                    </p>
                                </div>
                            </div>
                            
                            <div className="features-section">
                                <div className="feature-card">
                                    <div className="feature-image-container">
                                        <div 
                                            className={`feature-icon ${fadeState1}`} 
                                            style={{backgroundImage: `url(${allImages[imageIndex1]})`}}
                                        ></div>
                                    </div>
                                    <div className="feature-content">
                                        <h3 className="feature-title">AI 캐릭터</h3>
                                        <p className="feature-description">
                                            최첨단 인공지능 기술을 활용한 자연스러운 대화와 감정 표현으로 
                                            실제 사람과 대화하는 듯한 경험을 제공합니다.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="feature-card">
                                    <div className="feature-image-container">
                                        <div 
                                            className={`feature-icon ${fadeState2}`} 
                                            style={{backgroundImage: `url(${allImages[imageIndex2]})`}}
                                        ></div>
                                    </div>
                                    <div className="feature-content">
                                        <h3 className="feature-title">활발한 커뮤니티</h3>
                                        <p className="feature-description">
                                            다른 플레이어들과 경험을 공유하고 친구를 사귀며
                                            함께 성장하는 활발한 커뮤니티에 참여하세요.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="feature-card">
                                    <div className="feature-image-container">
                                        <div 
                                            className={`feature-icon ${fadeState3}`} 
                                            style={{backgroundImage: `url(${allImages[imageIndex3]})`}}
                                        ></div>
                                    </div>
                                    <div className="feature-content">
                                        <h3 className="feature-title">다양한 스토리</h3>
                                        <p className="feature-description">
                                            당신의 선택에 따라 달라지는 무한한 스토리 전개.
                                            모든 선택이 중요하며 당신만의 특별한 이야기를 만들어갑니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </MainContentStyled>
                    </div>
                    <Footer/>
                </div>
                <Popups />
            </div>
        </>
    );
}

export default Main;



