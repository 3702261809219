import React, { createContext, useContext, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import logo from "@/assets/images/logo.png";

// 📌 기본 색상 테마 (블랙 테마)
const defaultColors = {
  primary: "#007bff", // 파란색
  secondary: "#28a745", // 초록색
  third: "#6f42c1", // 보라색
  error: "#dc3545", // 붉은색
  alert: "#fd7e14", // 주황색
  background: "#121212", // 기본 다크 테마 배경
  text: "#ffffff", // 기본 텍스트 색상
  overlay: 'rgba(0, 0, 0, 0.7)', // 오버레이 색상 추가
  darkGray: '#1a1a1a',  // 짙은 회색 배경색 추가
  titleBg: '#212121',   // 타이틀 배경색 추가
};

// 📌 버튼 스타일 정의
const defaultButtonStyles = {
  add: { color: defaultColors.primary },
  delete: { color: defaultColors.error },
  update: { color: defaultColors.alert },
};

// 📌 레이아웃 스타일
const defaultLayoutStyles = {
  container: {
    background: defaultColors.background,
    width: "100%",
    height: "100%",
    paddingBottom: "56px", // 탭 메뉴 높이만큼 패딩 추가
  },
  title: {
    color: defaultColors.text,
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
    padding: ".2rem 0",
    margin: "1.5rem 0 1.5rem 0",
    backgroundColor: "#212121",
  },
};

// 📌 게시판 스타일
const defaultBoardStyles = {
  boardContainer: {
    padding: "1rem",
    margin: "0",
  },
  boardHeader: {
    padding: "1rem",
    fontSize: "1.6rem",
    fontWeight: "bold",
    borderBottom: `2px solid ${defaultColors.primary}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boardRow: {
    padding: "0.75rem",
    fontSize: "1.4rem",
    color: defaultColors.text,
    borderBottom: `1px solid ${defaultColors.text}`,
  },
  boardEmptyRow: {
    padding: "1rem",
    fontSize: "1.4rem",
    color: defaultColors.text,
    borderBottom: `1px solid ${defaultColors.text}`,
  },
};

// 📌 아이콘 스타일 수정
const defaultIconStyles = {
  logo: logo,
};

// 📌 탭 스타일 수정
const defaultTabStyles = {
  tabContainer: {
    display: "flex",
    height: "56px",
    position: "fixed",
    background: defaultColors.background,
    borderTop: `1px solid ${defaultColors.primary}`,
    zIndex: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  tab: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: defaultColors.text,
    cursor: "pointer",
    position: "relative",
    transition: "color 0.3s ease",
    height: "100%",
  },
  activeTab: {
    color: defaultColors.primary,
  },
  icon: {
    width: "24px",
    height: "24px",
    marginBottom: "4px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  label: {
    fontSize: "1rem",
  },
  indicator: {
    position: "absolute",
    bottom: 0,
    height: "2px",
    backgroundColor: defaultColors.primary,
    transition: "all 0.3s ease",
  },
};

// 📌 헤더 스타일 수정
const defaultHeaderStyles = {
  logo: {
    height: "32px",
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  // 팝업 헤더 스타일 추가
  popup: {
    height: "48px",
    padding: "0 1.6rem",
    background: "#061a1c",
    borderBottom: `1px solid ${defaultColors.primary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    
    title: {
      fontSize: "1.6rem",
      fontWeight: "600",
      color: "#ffffff",
    },
    
    close: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      color: "#ffffff",
      fontSize: "2rem",
      background: "transparent",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  }
};

// 📌 프로필 스타일 추가
const defaultProfileStyles = {
  name: {
    padding: "0.5rem 1rem",
    background: "#061a1c",
  },
};

// 📌 애니메이션 스타일 추가
const defaultAnimations = {
  slideUp: {
    keyframes: `
      @keyframes slideUp {
        0% {
          transform: translateY(0%);
          opacity: 0;
        }
        80% {
          transform: translateY(-80%);
          opacity: 0;
        }
        100% {
          transform: translateY(-100%);
          opacity: 1;
        }
      }
    `,
    animation: 'slideUp 0.2s ease-out forwards'
  }
};

// 📌 전체 스타일 타입
interface Theme {
  Color: typeof defaultColors;
  Layout: {
    Container: React.ComponentType<any>;
    Title: React.ComponentType<any>;
    LoginWrap: React.ComponentType<any>;
    Popup: React.ComponentType<any>;
  };
  Popup: {
    Wrap: React.ComponentType<any>;
    Container: React.ComponentType<any>;
    Footer: React.ComponentType<any>;
  };
  Icon: { [key in keyof typeof defaultIconStyles]: React.ComponentType<any> };
  Header: {
    Logo: React.ComponentType<any>;
    Popup: React.ComponentType<any>;
  };
  Animation: typeof defaultAnimations;
}

// 📌 기본 테마 정의
const defaultTheme: Theme = {
  Color: defaultColors,

  Layout: {
    Container: styled.div`
      height: initial;
      width: 100%;
      background: ${defaultLayoutStyles.container.background};
      .content-body {
        overflow: hidden;
        position: relative;
        height: calc(100vh - 56px);
      }
    `,
    Title: styled.h2`
      color: ${defaultLayoutStyles.title.color};
      font-size: ${defaultLayoutStyles.title.fontSize};
      font-weight: ${defaultLayoutStyles.title.fontWeight};
      text-align: ${defaultLayoutStyles.title.textAlign};
      padding: ${defaultLayoutStyles.title.padding};
      margin: ${defaultLayoutStyles.title.margin};
      background-color: ${defaultLayoutStyles.title.backgroundColor};
    `,
    LoginWrap: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: 100vh;
      padding-top: 10vh;
      padding: 10rem 5rem 0 5rem;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;

      .loginForm {
        width: 100%;
      }
      .loginForm label {
        color: ${defaultColors.text};
      }
      .loginForm input {
        color: ${defaultColors.text};
      }
      .loginForm p.error {
        color: ${defaultColors.error};
        font-size: 1.2rem;
      },

      .loginForm p.TextField-helperText {
        color: ${defaultColors.alert};
        font-size: 1.2rem;
      },
      .loginForm .buttonGroup button {
        color: ${defaultColors.text};
      }
    `,
    Popup: styled.div`
      position: fixed;
      top: 100%;
      width: 100%;
      height: 100%;
      z-index: 100;

    `,
  },
  Header: {
    Logo: styled.div`
      height: ${defaultHeaderStyles.logo.height};
      padding: ${defaultHeaderStyles.logo.padding};
      display: ${defaultHeaderStyles.logo.display};
      align-items: ${defaultHeaderStyles.logo.alignItems};
      justify-content: ${defaultHeaderStyles.logo.justifyContent};
      background: #061a1c;
      border-bottom: 1px solid ${defaultColors.primary};

      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 100%;
      }

      .left img {
        width: auto;
      }

      .left .site-title {
        font-size: 1.4rem;
        font-weight: 600;
        color:rgb(183, 255, 0);
        margin-left: 0.6rem;;
      }

      .left .site-introduce {
        font-size: 1.2rem;
        color:#ffffff;
        margin-left: 0.4rem;;
      }

      .right {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 65%;
      }

      .right a {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: middle;
      }
    `,
    // 팝업 헤더 스타일 컴포넌트 추가
    Popup: styled.div`
      height: ${defaultHeaderStyles.popup.height};
      padding: ${defaultHeaderStyles.popup.padding};
      background: ${defaultHeaderStyles.popup.background};
      border-bottom: ${defaultHeaderStyles.popup.borderBottom};
      display: ${defaultHeaderStyles.popup.display};
      align-items: ${defaultHeaderStyles.popup.alignItems};
      justify-content: ${defaultHeaderStyles.popup.justifyContent};

      .title {
        font-size: ${defaultHeaderStyles.popup.title.fontSize};
        font-weight: ${defaultHeaderStyles.popup.title.fontWeight};
        color: ${defaultHeaderStyles.popup.title.color};
        white-space: nowrap;       /* 한 줄로 표시 */
        overflow: hidden;          /* 넘치는 텍스트 숨김 */
        text-overflow: ellipsis;  /* 말줄임표 추가 */
        max-width: 100%;          /* 너비 제한 설정 (원하는 너비로 설정 가능) */
      }

      .close-btn {
        width: ${defaultHeaderStyles.popup.close.width};
        height: ${defaultHeaderStyles.popup.close.height};
        cursor: ${defaultHeaderStyles.popup.close.cursor};
        color: ${defaultHeaderStyles.popup.close.color};
        font-size: 3rem;
        background: ${defaultHeaderStyles.popup.close.background};
        border: ${defaultHeaderStyles.popup.close.border};
        display: ${defaultHeaderStyles.popup.close.display};
        align-items: ${defaultHeaderStyles.popup.close.alignItems};
        justify-content: ${defaultHeaderStyles.popup.close.justifyContent};
      }
    `,
  },
  Icon: Object.fromEntries(
    Object.entries(defaultIconStyles).map(([key, iconPath]) => [
      key,
      styled.img.attrs<{ isactive?: boolean|string, fill?: string }>({
        src: iconPath,
        alt: key
      })`
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: ${props => {
          if (props.fill) {
            // SVG 색상 변경을 위한 필터 생성
            // 먼저 이미지를 흑백으로 변환한 후 지정된 색상으로 채색
            const rgb = props.fill.match(/\w\w/g)?.map(hex => parseInt(hex, 16)) || [0, 0, 0];
            const [r, g, b] = rgb.map(val => val / 255);
            return `brightness(0) saturate(100%) invert(1) sepia(1) saturate(0) hue-rotate(0deg) brightness(${String(r)}) contrast(1) saturate(${String(1/r)}) hue-rotate(${String(Math.atan2(b-r, g-r))}rad)`;
          }
          return props.isactive == 'false' ? 'brightness(0.5)' : 'brightness(1)';
        }};
      `,
    ])
  ) as unknown as Theme["Icon"],


  Popup: {
    Wrap: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;

      ${defaultAnimations.slideUp.keyframes}
      animation: ${defaultAnimations.slideUp.animation};
    `,
    Container: styled.div`
      background: #121212;
      height: 100%;
      .scroll-container{
        height: 100%;
        overflow-y: auto;
        padding-bottom: 25.5rem;
      }
      .content {
        font-size: 1.5rem;
        color: rgb(255, 255, 255);
        line-height: 1.6;
        word-break: break-all;
        white-space: pre-wrap;
      }
    `,
    Footer: styled.div`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      display: flex;
      gap: 1rem;
      padding: 1.6rem;
      background: #1e1e1e;
      border-top: 1px solid #007bff;

      button {
        height: 4.8rem;
        border-radius: 4px;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
      }
    `,
  },

  Animation: defaultAnimations,
};

// 📌 테마 컨텍스트 생성
const ThemeContext = createContext<Theme>(defaultTheme);

const GlobalStyle = createGlobalStyle`
  html {
    scrollbar-gutter: stable;
    -webkit-overflow-scrolling: touch;
  }
`;

// 📌 ThemeProvider 정의
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const updateStyles = (newStyles: Partial<Theme>) => {
    setTheme((prev) => ({ ...prev, ...newStyles }));
  };

  return (
    <ThemeContext.Provider value={{ ...theme }}>
      <GlobalStyle />
      {children}
    </ThemeContext.Provider>
  );
};

// 📌 테마 가져오는 커스텀 훅
export const useTheme = () => {
  return useContext(ThemeContext);
};