import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { DividerProps, styledProps } from '../types';

const StyledComponent = styled.hr<styledProps>`
  ${Styles.Divider as any}
  ${props => props.$sx}
`;

/**
 * 박스 컴포넌트: 레이아웃의 기본 구조를 제공하는 래퍼 컴포넌트
 * @param props DividerProps - 박스 설정을 위한 props
 * @param ref HTMLDivElement에 대한 ref
 */
const Divider = React.memo(React.forwardRef<HTMLDivElement, DividerProps>((props, ref) => {
  const {
    component: Component = 'hr', // 렌더링할 HTML 요소 (기본값: div)
    children,                    // 자식 요소
    className,
    orientation = 'horizontal',
    variant = 'fullWidth',
    textAlign = 'center',
    sx,                         // 추가 스타일 객체
    ...other
  } = props;

  const notHr = children || variant !== 'fullWidth' || orientation === 'vertical';

  const rootClassName = React.useMemo(() => {
    return [
      className,
      children && 'Divider-withChildren',
      notHr && orientation && `Divider-${orientation}`,
      notHr && variant && `Divider-${variant}`,
      notHr && textAlign && `Divider-${textAlign}`
    ].filter((item): item is string => Boolean(item)).join(' ');
  }, [className, orientation, variant, textAlign]);

  return (
    <StyledComponent
      as={notHr ? 'div' : Component}
      className={rootClassName}
      role={notHr ? "separator" : undefined}
      aria-orientation={notHr ? orientation : undefined}
      ref={ref}
      $sx={sx}
      {...other}
    >
      {children && <span>{children}</span>}
    </StyledComponent>
  );
}));

Divider.displayName = 'Divider';

export default Divider;
