import React from 'react';
import styled, { css } from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { LabelProps, styledProps } from '../types';

const StyledComponent = styled.label<styledProps>`
  ${Styles.FormLabel as any}
  ${props => props.$sx}
`;

const FormLabel = React.memo(React.forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const {
    component: Component = 'label',
    className,
    children,
    htmlFor,
    required,
    sx,
    ...other
  } = props;

  // 필수 항목 표시
  const requiredSpan = required ? (
    <span className={`${name}-required`} aria-hidden="true">
      *
    </span>
  ) : null;

  return (
    <StyledComponent
      as={Component}
      className={className}
      htmlFor={htmlFor}
      $sx={sx}
      ref={ref}
      {...other}
    >
      {children}
      {requiredSpan}
    </StyledComponent>
  );
}));

FormLabel.displayName = 'FormLabel';

export default FormLabel;
