import Footer from '@/pages/Footer';
import Header from '@/pages/Header';
import React from 'react';
import styled from 'styled-components';
import Popups from './Popups/Popups';


const MainContentStyled = styled.div`
  padding: 30px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  max-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  
  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }
`;

const TermsSection = styled.div`
  margin-bottom: 340px;
`;

const TermsTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
`;

const ArticleTitle = styled.h2`
  font-size: 20px;
  margin: 25px 0 15px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 15px;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  line-height: 1.5;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${props => props.active ? '#007bff' : '#f0f0f0'};
  color: ${props => props.active ? '#fff' : '#333'};
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

const Private = (props: any) => {
    const [activeTab, setActiveTab] = React.useState<'terms' | 'privacy'>('terms');

    return (
        <>
            <div className={'main-frame'}>
                <div className={'main-container'}>
                    <Header />
                    <div className={'content-body'}>
                        <MainContentStyled>
                            <TabContainer>
                                <Tab 
                                    active={activeTab === 'terms'} 
                                    onClick={() => setActiveTab('terms')}
                                >
                                    이용약관
                                </Tab>
                                <Tab 
                                    active={activeTab === 'privacy'} 
                                    onClick={() => setActiveTab('privacy')}
                                >
                                    개인정보 처리방침
                                </Tab>
                            </TabContainer>

                            {activeTab === 'terms' && (
                                <TermsSection>
                                    <TermsTitle>이용약관</TermsTitle>
                                    
                                    <ArticleTitle>제1조 (목적)</ArticleTitle>
                                    <Paragraph>
                                        본 약관은 게임회사(이하 "회사")가 제공하는 게임 및 관련 서비스(이하 "서비스")의 이용과 관련하여 회사와 이용자 간의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제2조 (정의)</ArticleTitle>
                                    <Paragraph>
                                        "게임"이란 회사가 제공하는 모바일, PC, 콘솔 등의 플랫폼에서 플레이할 수 있는 게임 및 관련 콘텐츠를 의미합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        "서비스"란 게임과 관련된 모든 기능, 커뮤니티, 결제 서비스 등을 포함하는 모든 부가 서비스를 의미합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        "이용자"란 본 약관에 동의하고 서비스를 이용하는 개인 또는 법인을 의미합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        "아이디(ID)"란 이용자의 식별 및 서비스 이용을 위해 설정한 문자 또는 숫자의 조합을 의미합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        "유료 콘텐츠"란 이용자가 일정 금액을 지불하고 이용할 수 있는 게임 아이템, 캐릭터, 화폐 등의 가상 재화를 의미합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        "커뮤니티"란 이용자가 서비스 내에서 의견을 나누고 정보를 공유할 수 있는 게시판 및 채팅 기능을 의미합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제3조 (서비스 제공 및 변경)</ArticleTitle>
                                    <Paragraph>
                                        회사는 이용자에게 게임과 관련된 서비스를 제공합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        회사는 운영상 필요에 따라 서비스의 내용을 변경하거나 일부 기능을 추가·삭제할 수 있으며, 이 경우 사전에 공지합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        회사는 이용자의 서비스 이용을 방해하지 않는 범위에서 정기 또는 긴급 점검을 실시할 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제4조 (이용자의 의무)</ArticleTitle>
                                    <Paragraph>
                                        이용자는 본 약관 및 관계 법령을 준수해야 합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        이용자는 서비스 이용 시 회사의 운영 정책을 따르며, 다음 행위를 금지합니다.
                                    </Paragraph>
                                    <List>
                                        <ListItem>타인의 계정을 도용하는 행위</ListItem>
                                        <ListItem>서비스 내 부정 행위(핵, 불법 프로그램, 버그 악용 등)</ListItem>
                                        <ListItem>타 이용자에 대한 괴롭힘, 욕설, 허위 정보 유포 행위</ListItem>
                                        <ListItem>불법적인 콘텐츠를 게시하거나 전송하는 행위</ListItem>
                                    </List>
                                    <Paragraph>
                                        이용자는 본인의 계정과 결제 정보 보호에 대한 책임이 있으며, 이를 타인에게 공유하거나 양도할 수 없습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제5조 (유료 콘텐츠 및 결제)</ArticleTitle>
                                    <Paragraph>
                                        이용자는 회사가 제공하는 결제 방식을 통해 유료 콘텐츠를 구매할 수 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        유료 콘텐츠의 환불은 관계 법령 및 회사의 환불 정책에 따라 처리됩니다.
                                    </Paragraph>
                                    <Paragraph>
                                        미성년자가 법정대리인의 동의 없이 결제한 경우, 법정대리인은 회사에 이를 증명하고 환불을 요청할 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제6조 (계정 정지 및 이용 제한)</ArticleTitle>
                                    <Paragraph>
                                        회사는 이용자가 본 약관을 위반하거나 부정 행위를 한 경우, 서비스 이용을 일시적 또는 영구적으로 제한할 수 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        이용 제한 조치에 대한 이의 신청은 회사의 고객센터를 통해 접수할 수 있으며, 회사는 합리적인 범위 내에서 이를 검토합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제7조 (개인정보 보호)</ArticleTitle>
                                    <Paragraph>
                                        회사는 이용자의 개인정보를 관련 법령에 따라 보호합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        개인정보 처리와 관련한 세부 사항은 "개인정보 처리방침"에서 확인할 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제8조 (면책 조항)</ArticleTitle>
                                    <Paragraph>
                                        회사는 이용자의 고의 또는 과실로 인해 발생한 손해에 대해 책임을 지지 않습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        회사는 천재지변, 정전, 시스템 장애 등의 불가항력적인 사유로 인한 서비스 중단에 대해 책임을 지지 않습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제9조 (약관의 변경 및 공지)</ArticleTitle>
                                    <Paragraph>
                                        본 약관은 필요 시 개정될 수 있으며, 변경 사항은 사전 공지됩니다.
                                    </Paragraph>
                                    <Paragraph>
                                        이용자가 변경된 약관에 동의하지 않는 경우, 서비스 이용을 중단할 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제10조 (분쟁 해결)</ArticleTitle>
                                    <Paragraph>
                                        본 약관과 관련하여 분쟁이 발생하는 경우, 회사와 이용자는 원만한 해결을 위해 노력합니다.
                                    </Paragraph>
                                    <Paragraph>
                                        분쟁이 해결되지 않을 경우, 회사의 본사 소재지 관할 법원을 제1심 관할 법원으로 합니다.
                                    </Paragraph>
                                </TermsSection>
                            )}

                            {activeTab === 'privacy' && (
                                <TermsSection>
                                    <TermsTitle>개인정보 처리방침</TermsTitle>
                                    
                                    <ArticleTitle>제1조 (목적)</ArticleTitle>
                                    <Paragraph>
                                        본 개인정보 처리방침은 회사가 이용자의 개인정보를 어떻게 수집, 이용, 보호하는지 설명합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제2조 (수집하는 개인정보 항목 및 수집 방법)</ArticleTitle>
                                    <Paragraph>
                                        회사는 다음과 같은 개인정보를 수집할 수 있습니다.
                                    </Paragraph>
                                    <List>
                                        <ListItem>필수 정보: 아이디, 비밀번호, 이메일, 닉네임</ListItem>
                                        <ListItem>선택 정보: 프로필 사진, 휴대전화 번호</ListItem>
                                        <ListItem>결제 정보: 신용카드 정보, 거래 내역</ListItem>
                                    </List>
                                    <Paragraph>
                                        수집 방법
                                    </Paragraph>
                                    <List>
                                        <ListItem>회원가입 시 이용자가 직접 입력</ListItem>
                                        <ListItem>서비스 이용 과정에서 자동 수집 (IP 주소, 접속 로그 등)</ListItem>
                                    </List>
                                    
                                    <ArticleTitle>제3조 (개인정보의 이용 목적)</ArticleTitle>
                                    <Paragraph>
                                        회사는 다음의 목적을 위해 개인정보를 이용합니다.
                                    </Paragraph>
                                    <List>
                                        <ListItem>회원 관리: 가입 확인, 본인 인증, 계정 보호</ListItem>
                                        <ListItem>서비스 제공: 게임 플레이 데이터 저장, 커뮤니티 이용</ListItem>
                                        <ListItem>고객 지원: 문의 처리, 불만 해결</ListItem>
                                        <ListItem>법적 의무 준수: 관련 법령에 따른 정보 제공</ListItem>
                                    </List>
                                    
                                    <ArticleTitle>제4조 (개인정보의 보관 및 파기)</ArticleTitle>
                                    <Paragraph>
                                        이용자의 개인정보는 회원 탈퇴 시 즉시 삭제됩니다. 단, 법적 의무 준수를 위해 일정 기간 보관할 수 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        보존 기간이 만료된 개인정보는 안전한 방법으로 즉시 파기합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제5조 (개인정보의 제3자 제공)</ArticleTitle>
                                    <Paragraph>
                                        회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        법적 요구가 있거나 이용자가 동의한 경우에 한해 제3자에게 제공할 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제6조 (이용자의 권리 및 행사 방법)</ArticleTitle>
                                    <Paragraph>
                                        이용자는 언제든지 자신의 개인정보를 조회, 수정, 삭제할 수 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        이용자는 개인정보 처리에 대한 동의를 철회할 수 있으며, 이 경우 서비스 이용이 제한될 수 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        관련 요청은 고객센터를 통해 접수할 수 있으며, 회사는 법정 기한 내 처리합니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제7조 (개인정보 보호를 위한 기술적·관리적 대책)</ArticleTitle>
                                    <Paragraph>
                                        회사는 이용자의 개인정보 보호를 위해 보안 시스템을 구축하고 있습니다.
                                    </Paragraph>
                                    <Paragraph>
                                        이용자의 개인정보는 암호화 저장되며, 접근 권한이 제한됩니다.
                                    </Paragraph>
                                    <Paragraph>
                                        정기적인 보안 점검을 통해 개인정보 보호를 강화하고 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제8조 (개인정보 처리방침의 변경)</ArticleTitle>
                                    <Paragraph>
                                        본 개인정보 처리방침은 필요 시 개정될 수 있으며, 변경 사항은 사전 공지됩니다.
                                    </Paragraph>
                                    <Paragraph>
                                        중요한 변경 사항이 있을 경우, 이용자의 동의를 받을 수 있습니다.
                                    </Paragraph>
                                    
                                    <ArticleTitle>제9조 (문의 및 연락처)</ArticleTitle>
                                    <Paragraph>
                                        개인정보 보호책임자: 주식회사 너드폭스
                                    </Paragraph>
                                    <Paragraph>
                                        이메일: nerdfox2024@gmail.com
                                    </Paragraph>
                                </TermsSection>
                            )}
                        </MainContentStyled>
                    </div>
                    <Footer/>
                </div>
                <Popups />
            </div>
        </>
    );
}

export default Private;



