import {useLocation, useNavigate} from 'react-router-dom';
import { Box, Button, Container, Typography } from '@/components';
import SignTitle from './SignTitle';
import React, {useEffect} from "react";
import {useAuth} from "@/context/AuthContext.ts";

const SocialSignUpSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tokenLogin } = useAuth();

    useEffect(() => {
        // URL에서 access_token 파라미터 추출
        const params = new URLSearchParams(location.search);
        const accessToken = params.get('access_token');

        if (accessToken) {
            tokenLogin(accessToken);
        } else {
            navigate('/login');
        }
    }, [location, navigate]);

    return (
        <div className="welcome-wrapper">
            소셜 로그인 처리 중...
        </div>
    );
}
export default SocialSignUpSuccess;
