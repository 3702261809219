import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <ErrorCode>404</ErrorCode>
        <Title>페이지를 찾을 수 없습니다</Title>
        <Description>
          요청하신 페이지가 삭제되었거나 잘못된 경로입니다.
        </Description>
        <HomeButton onClick={() => navigate('/')}>
          홈으로 돌아가기
        </HomeButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const Content = styled.div`
  text-align: center;
  max-width: 500px;
  padding: 2rem;
`;

const ErrorCode = styled.h1`
  font-size: 8rem;
  font-weight: bold;
  color: #007bff;
  margin: 0;
  line-height: 1;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  color: #343a40;
  margin: 2rem 0;
`;

const Description = styled.p`
  font-size: 1.6rem;
  color: #6c757d;
  margin-bottom: 3rem;
`;

const HomeButton = styled.button`
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    transform: translateY(1px);
  }
`;

export default NotFound; 