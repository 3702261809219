import { LoginType } from '@/components/auth/AuthProvider';
import { IdentityVerificationDto, UserDto } from '@/types/TransactionTypes';
import { createContext, useContext } from 'react';
import { Method } from 'axios';

// 사용자 정보 인터페이스
export interface User extends UserDto.UserDtoResponse{
  isAuthenticated: boolean; // 인증 상태
}

export interface IslandButtonParam {
  id: string;
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  requireAuth?: boolean;
}

export interface IslandButtonType {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  requireAuth: boolean;
}

export interface IslandButtonsState {
  [key: string]: IslandButtonType;
}

// 인증 컨텍스트에서 제공할 값들의 타입 정의
export interface AuthContextType {
  user: User | null; // 현재 로그인한 사용자 정보
  isAuthenticated: boolean; // 현재 인증 상태
  register: (info: UserDto.CreateRequest, callback?: (user: User) => void) => Promise<User | null>; // 회원가입 함수
  login: (email: string, password: string, callback?: (user: User) => void) => Promise<User | null>; // 로그인 함수
  emailIdentityVerificationSendCode: (email: string) => Promise<IdentityVerificationDto.IdentityVerificationDtoResponse | null>; // 이메일 인증 코드 발송 함수
  emailIdentityCodeVerification: (id: number, code: string) => Promise<IdentityVerificationDto.IdentityVerificationDtoResponse | null>; // 이메일 인증 코드 확인 함수
  tokenLogin: (token: string) => Promise<User | null>; // 토큰 로그인 함수
  socialLogin: (type: LoginType, callback?: (user: User) => void) => Promise<User | null>; // 소셜 로그인 함수
  logout: () => void; // 로그아웃 함수
  // 네비게이션 함수 타입 추가
  $navi: (path: string) => void;
  $home: () => void;
  $sessionhome: () => void;
  // fetchData 함수 타입 추가
  fetchData: <T = any>(
    path: string,
    method?: Method,
    body?: any,
    headers?: Record<string, string>
  ) => Promise<T>;
}

// 인증 컨텍스트 생성 (초기값은 undefined)
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// 인증 컨텍스트를 쉽게 사용할 수 있게 해주는 커스텀 훅
export const useAuth = () => {
  const context = useContext(AuthContext);

  // AuthProvider 외부에서 useAuth를 사용하려고 할 때 에러 발생
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
