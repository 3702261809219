import React from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type SpacingValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type ColorValue = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export const ValidateMessage = {
  userNameKor: '이름은 2자 이상의 한글이어야 합니다',
  email: '이메일 형식이 올바르지 않습니다.',
  password: '8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.',
  passwordConfirm: '비밀번호가 일치하지 않습니다.',
  phone: '올바른 휴대폰 번호 형식이 아닙니다. (예: 010-1234-5678)',
  tel: '올바른 전화번호 형식이 아닙니다. (예: 02-123-4567)',
  url: '올바른 URL 형식이 아닙니다. (예: https://example.com)',
  korean: '한글만 입력 가능합니다.',
  disallowKorean: '한글은 입력할 수 없습니다.',
  english: '영문자만 입력 가능합니다.',
  disallowEnglish: '영문자는 입력할 수 없습니다.',
  number: '숫자만 입력 가능합니다.',
  disallowNumber: '숫자는 입력할 수 없습니다.',
  alphaNumber: '영문자와 숫자만 입력 가능합니다.',
  alphaNumberSpecial: '영문자, 숫자, 특수문자만 입력 가능합니다.',
  koreanNumber: '한글과 숫자만 입력 가능합니다.',
  koreanEnglish: '한글과 영문자만 입력 가능합니다.',
  required: '필수 입력 필드입니다.',
  minLength: '최소 길이는 2자 이상이어야 합니다.',
  maxLength: '최대 길이는 20자 이하이어야 합니다.',
} as const;

export const ValidatePattern = {
  userNameKor: '[ㄱ-ㅎ가-힣]',
  email: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  phone: '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
  tel: '^(0[2-7][0-9]{0,1})-?([0-9]{3,4})-?([0-9]{4})$',
  url: '^(https?:\\/\\/)?([\\w-]+\\.)+[\\w-]+(\\/[\\w-./?%&=]*)?$',
  password: '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,16}$',
} as const;

export const InputRestrictionPattern = {
  userNameKor: 'ㄱ-ㅎ가-힣',
  email: 'a-zA-Z0-9@._-',
  phone: '0-9-',
  tel: '0-9-',
  url: '\\w:/.-',
  number: '0-9',
  korean: '가-힣',
  english: 'a-zA-Z',
  alphaNumber: 'a-zA-Z0-9',
  koreanNumber: 'ㄱ-ㅎㅏ-ㅣ가-힣0-9',
  koreanEnglish: 'ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z',
  password: 'A-Za-z0-9\\d@$!%*#?&',
} as const;

// 타입 추출을 위한 타입 정의
export type ValidateMessageType = typeof ValidateMessage;
export type ValidatePatternType = typeof ValidatePattern;
export type InputRestrictionPatternType = typeof InputRestrictionPattern;

// CSS 스타일 객체 타입 정의
type CSSObject = {
  [key: string]: string | number | CSSObject | undefined;
};

// styled-components 공통 타입 정의
export interface styledProps {
  $sx?: CSSObject;
  $style?: CSSObject;
}

// 공통 타입
export interface ComponentProps {
  animation?: 'slideLeft' | 'slideRight' | 'fadeIn' | 'fadeOut';
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  disableGutters?: boolean;
  gutter?: SpacingValue | string;
  gutterX?: SpacingValue | string;
  gutterY?: SpacingValue | string;
  id?: string;
  role?: string;
  spacing?: number | string | Record<string, number | string>;
  style?: React.CSSProperties;
  sx?: CSSObject;
}

// Container
export interface ContainerProps extends ComponentProps {
  fixed?: boolean;
  maxWidth?: Breakpoint | string;
  page?: boolean;
}

// Stack
export interface StackProps extends ComponentProps {
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
  divider?: React.ReactNode;
}

// Box
export interface BoxProps extends ComponentProps {
}

// Divider
export interface DividerProps extends ComponentProps {
  flexItem?: boolean;
  orientation?: 'horizontal' | 'vertical';
  textAlign?: 'left' | 'center' | 'right';
  variant?: 'fullWidth' | 'inset' | 'middle' | string;
}

// Icon
export const ICON_SIZES = {
  small: '1.25rem',
  medium: '1.5rem',
  large: '1.75rem',
} as const;
export interface IconProps extends ComponentProps {
  ariaLabel?: string;
  className?: string;
  color?: 'currentColor' | ColorValue | string;
  disabled?: boolean;
  extension?: 'svg' | string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large' | string;
  variant?: 'filled' | 'outlined' | 'rounded' | 'sharp' | 'twoTone';
}

// IconButton
export interface IconButtonProps extends ComponentProps {
  color?: ColorValue | string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  padding?: string;
  size?: 'small' | 'medium' | 'large' | string;
  type?: 'button' | 'submit' | 'reset';
}

// Button
export interface ButtonProps extends IconButtonProps {
  endIcon?: React.ReactNode;
  form?: string;
  fullWidth?: boolean;
  href?: string;
  rounded?: boolean | string;
  startIcon?: React.ReactNode;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'contained' | 'outlined' | 'text';
}

// ValidationError
export interface ValidationProps {
  field: string;
  value: string;
  errorMessage?: string;
  isValid: boolean;
  type: string;
}

// Form 공통
export interface FormProps extends ComponentProps {
  autoFocus?: boolean;
  color?: ColorValue | string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  helperTextId?: string;
  label?: string | [string, 'fixed' | 'hidden' | 'start' | 'end' | 'top' | 'bottom'];
  labelPlacement?: 'fixed' | 'hidden' | 'start' | 'end' | 'top' | 'bottom';
  labelProps?: LabelProps;
  name?: string;
  onBlur?: (value: any) => void;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
  onError?: (error: ValidationProps) => void;
  onFocus?: (value: any) => void;
  onInput?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  onKeyPress?: (value: any) => void;
  onKeyUp?: (value: any) => void;
  readOnly?: boolean;
  required?: boolean;
  validate?: boolean | 'auto';
  value?: any;
}

// Label
export interface LabelProps extends ComponentProps {
  htmlFor?: string;
  required?: boolean;
}

// FormHelperText
export interface FormHelperTextProps extends ComponentProps {
}

export interface InputProps extends FormProps {
  autoComplete?: 'nope' | 'off' | 'on' | 'username' | 'email' | 'current-password' | 'new-password' | 'one-time-code' | 'off';
  checked?: boolean;
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' | 'search' | 'color' | 'file' | 'hidden' | 'image' | 'range' | string;
}

// TextField
export interface TextFieldProps extends InputProps {
  fullWidth?: boolean;
  mask?: string;
  multiline?: boolean;
  pattern?: string;
  regx?: string;
  showError?: boolean;
  placeholder?: string;
  rounded?: boolean | string;
  rows?: number;
  rowsMax?: number;
  size?: 'small' | 'medium' | 'large';
  variant?: 'outlined' | 'filled' | 'standard';
  maxLength?: number;
  minLength?: number;
}

// Checkbox
export interface CheckboxProps extends FormProps {
  checked?: boolean;
  indeterminate?: boolean;
  size?: 'small' | 'medium' | 'large';
}

// Radio
export interface RadioProps extends FormProps {
  checked?: boolean;
}

// select
export interface SelectProps extends FormProps {
  options: { value: string; label: string }[];
}
