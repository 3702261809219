import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { FormHelperTextProps, styledProps } from '../types';

const StyledComponent = styled.p<styledProps>`
  ${Styles.FormHelperText as any}
  ${props => props.$sx}
`;

const FormHelperText = React.memo(React.forwardRef<HTMLParagraphElement, FormHelperTextProps>((props, ref) => {
  const {
    component: Component = 'p',
    className,
    children,
    sx,
    ...other
  } = props;

  return (
    <StyledComponent
      as={Component}
      className={className}
      ref={ref}
      $sx={sx}
      {...other}
    >
      {children}
    </StyledComponent>
  );
}));

FormHelperText.displayName = 'FormHelperText';

export default FormHelperText;