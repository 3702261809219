import React from 'react';
import {useTheme} from './ThemeContext';
import styled from 'styled-components';

interface FooterProps {
}

const FooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  color: #666;
  background-color: #121212;
  border-top: 1px solid #333;
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .company-info {
    margin-bottom: 8px;
  }
  
  .company-info p {
    margin: 5px 0;
  }
  
  .copyright {
    margin-top: 12px;
    font-size: 12px;
  }
  
  address {
    font-style: normal;
    line-height: 1.2;
  }
`;

const Footer = () => {
  const theme = useTheme();

  return (
    <FooterStyled>
      <div className="footer-content">
        <div className="company-info">
          <h3>너드폭스 게임즈</h3>
          <address>
            <p>(15012) 경기도 시흥시 서울대학로 77 골드테크노밸리 927호</p>
          </address>
          <p>사업자등록번호: 148-86-03486 | 법인등록번호: 135511-0443081 | 통신판매번호: 2024-경기시흥-2236</p>
        </div>
        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} nerdfox co.,ltd. All Rights Reserved.</p>
        </div>
      </div>
    </FooterStyled>
  );
};

export default Footer;