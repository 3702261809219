import React, { ComponentType, useEffect, useState } from 'react';
import { RContext, rootContext } from "./RContext.ts";

// WITH_CONTEXT_PROPS 인터페이스 정의
export interface WITH_CONTEXT_PROPS {
    parent?: RContext;
    context?: RContext;
    defaultValue?: any;
}

// withContext HOC 정의
function withContext<T extends object>(WrappedComponent: ComponentType<T & WITH_CONTEXT_PROPS>, key?:string) {
    // 컴포넌트를 감싸는 HOC
    const WithContextComponent = (props: T) => {
        // rContext를 상태로 관리
            const [context] = useState(() => new RContext());

        useEffect(() => {
            if(!key) key = WrappedComponent.displayName || WrappedComponent.name || 'Component';
            rootContext.addComponent(key, context);
            // console.log(`Context added : ${key}`);

            // $on 메서드 동적 추가
            if(!context.$on){
                context.$on = (event: string, callback: (data: any) => void) => {
                    // context.$on(event, callback);
                    console.log(`$on event: ${event}`);
                };
            }

            return () => {
                // 컴포넌트가 언마운트될 때 정리 작업 수행
                // delete rootContext.components[key]; // 등록된 컴포넌트 제거
            };
        }, [context]);

        // context를 추가하여 WrappedComponent에 전달
        return <WrappedComponent {...props} context={context} />;
    };

    WithContextComponent.displayName = `WithContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithContextComponent;
}

export default withContext;