import React from 'react';
import styled from 'styled-components';
import {ComponentProps, styledProps} from '@/components/common/types';
import {Typography} from '@/components';


const SignTitleWrapper = styled.div<styledProps>`
  ${props => props.$style}
  ${props => props.$sx}
`;

const SignTitle = (props: ComponentProps & { subtitle?: string }) => {
  const {
    id,
    children,
    sx,
    subtitle = '아름다운 커뮤니티',
    ...other
  } = props;

  const rootStyles = React.useMemo(() => ({
    textAlign: 'center',
  }), []);

  return (
    <SignTitleWrapper
      id={id}
      $style={rootStyles}
      $sx={sx}
      {...other}
    >
      <img 
        alt="Bello Board 로고"
        style={{
          height: '8.5rem'
        }}
      />
      <Typography
        variant='h1'
        sx={{
          fontSize: '3.5rem',
          fontWeight: 'bold',
          letterSpacing: '-0.02em',
          color: 'var(--primary-color)',
          lineHeight: '48px',
        }}
      >
        <span>Bello Board</span>
      </Typography>
      <Typography
        sx={{
          marginTop: 'var(--spacing-1)',
          fontSize: '1.2rem',
          color: '#ffffff',
        }}
      >
        <span>{subtitle}</span>
      </Typography>
    </SignTitleWrapper>
  );
};


export default SignTitle;