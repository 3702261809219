import React, { useState } from 'react';
import { AuthContext, User, IslandButtonsState, IslandButtonType, IslandButtonParam } from '@/context/AuthContext';
import { IdentityVerificationDto, UserDto } from '@/types/TransactionTypes';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosRequestConfig, Method } from "axios";
import { useCommonView } from '@/pages/Common/CommonViewProvider';
import { useTheme } from '@/pages/ThemeContext';

// 상수 분리
const LOCAL_STORAGE_USER_KEY = 'user';

// 타입 정의 개선
type AuthProviderProps = {
  children: React.ReactNode;
};

export enum LoginType {
  GOOGLE = 'google',
  APPLE = 'apple',
  KAKAO = 'kakao',
  NAVER = 'naver',
  EMAIL = 'email'
}

// 라우터의 location state 타입 정의
export interface LocationState {
  from: {
    pathname: string;
  };
}


const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const { $alert } = useCommonView();
  const { Icon } = useTheme();
  
  // 초기화 로직 분리 및 에러 처리 강화
  const initializeUser = (): User | null => {
    try {
      const storedUser = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
      if (!storedUser) return null;

      const parsedUser = JSON.parse(storedUser) as User;
      // 유효성 검증 추가
      if (!parsedUser.email) {
        throw new Error('Invalid user data');
      }

      return parsedUser;
    } catch (error) {
      console.error('사용자 정보 초기화 중 오류 발생:', error);
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      return null;
    }
  };


  const [user, setUser] = useState<User | null>(initializeUser);


  // 📌 이메일로 회원가입
  const emailRegister = async (info:UserDto.CreateRequest) : Promise<User|null> => {
    try {
      const query = `/api/users/register`;
      const response = await fetchData<User>(query, "POST", info);
      response.isAuthenticated = true;
      return response;
    } catch (error) {
      console.error("게시글 조회 오류:", error);
    }
    return null;
  };

  // 📌 이메일로 로그인하기
  const emailLogin = async (id: string, password: string) : Promise<User|null> => {
    try {
      const query = `/api/users/login`;
      const response = await fetchData<User>(query, "POST", {id: id, password: password});
      response.isAuthenticated = true;
      return response;
    } catch (error) {
      console.error("게시글 조회 오류:", error);
    }
    return null;
  };

  // 📌 이메일로 회원가입
  const register = async (info:UserDto.CreateRequest,callback?: (user:User) => void): Promise<User|null> => {
    let user:User|null = null;
    if (!info.id?.trim() || !info.password?.trim()) {
      throw new Error('이메일과 비밀번호는 필수입니다.');
    }
    try {
      user = await emailRegister(info);
    } catch (error) {
      console.error('로그인 처리 중 오류 발생:', error);
      throw error; // 에러를 상위로 전파
    }

    if(user){
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
      setUser(user);
    }
    if(user) callback?.(user);
    return user;
  };

  // 📌 액세스 토큰로그인
  const tokenLogin = async (token: string): Promise<User | null> => {
    let user: User | null = null;
    try {
      const user = await fetchData<User>('/api/users/token/' + token, 'GET');
      if(user){
        user.isAuthenticated = true;
        setUser(user);
        localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
        $navi('/main');
      }
    } catch (error) {
      console.error('인증토큰 로그인 처리 중 오류 발생:', error);
      throw error;
    }

    return user;
  };

  // 📌 소셜로그인
  const socialLogin = async (provider:LoginType,callback?: (user:User) => void): Promise<User|null> => {
    let user: User | null = null;
    try {
      const response = await fetchData<string>('/api/users/oauth/authorization/' + provider, 'GET');
      if (response) {
        window.location.href = response;
      }
    } catch (error) {
      console.error('소셜 로그인 처리 중 오류 발생:', error);
      throw error;
    }

    return user;
  };

  // 📌 이메일로 로그인
  const login = async (email: string, password: string, callback?: (user:User) => void): Promise<User|null> => {
    let user:User|null = null;
    if (!email?.trim() || !password?.trim()) {
      throw new Error('이메일과 비밀번호는 필수입니다.');
    }
    try {
      user = await emailLogin(email, password);
    } catch (error) {
      console.error('로그인 처리 중 오류 발생:', error);
      throw error; // 에러를 상위로 전파
    }

    if(user){
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
      setUser(user);
    }
    if(user) callback?.(user);
    return user;
  };

  const logout = (): void => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      setUser(null);
    } catch (error) {
      console.error('로그아웃 처리 중 오류 발생:', error);
      throw error; // 에러를 상위로 전파
    }
  };

  // 📌 이메일 인증
  const emailIdentityVerificationSendCode = async (email:string): Promise<IdentityVerificationDto.IdentityVerificationDtoResponse|null> => {
    let response:IdentityVerificationDto.IdentityVerificationDtoResponse|null = null;
    try {
      const query = `/api/identity-verification/send-code`;
      response = await fetchData<IdentityVerificationDto.IdentityVerificationDtoResponse>(query, "POST", {type: 'EMAIL', target: email});
    } catch (error) {
      console.error("이메일 인증요청 실패:", error);
    }
    return response;
  };

  // 📌 이메일 인증확인
  const emailIdentityCodeVerification = async (id:number, code:string): Promise<IdentityVerificationDto.IdentityVerificationDtoResponse|null> => {
    let response:IdentityVerificationDto.IdentityVerificationDtoResponse|null = null;
    try {
      const query = `/api/identity-verification/verify-code`;
      response = await fetchData<IdentityVerificationDto.IdentityVerificationDtoResponse>(query, "PUT", {id: id, code: code});
    } catch (error) {
      console.error("이메일 인증확인요청 실패:", error);
    }
    return response;
  };

  // 네비게이션 함수들 추가
  const $navi = (path: string) => {
    navigate(path);
  };

  const $home = () => {
    navigate('/main');
  };

  const $sessionhome = () => {
    if(user?.isAuthenticated){
      $home();
    }
  };

  // API 인스턴스 생성
  const api = axios.create({
    // baseURL: 'http://localhost:50200',
  });

  const fetchData = async <T = any>(
    path: string,
    method: Method = "GET",
    body?: any,
    headers: Record<string, string> = { "Content-Type": "application/json" }
  ): Promise<T> => {
    try {
      if (user?.token) {
        headers["Authorization"] = `Bearer ${user.token}`;
      }

      const config: AxiosRequestConfig = {
        url: path,
        method,
        headers,
        data: body
      };

      const response = await api(config);
      if(!window.ContextScopeData) window.ContextScopeData = {};
      window.ContextScopeData[path] = response.data;
      return response.data;
    } catch (error: any) {
      console.error(`API 호출 오류 [${method}] ${path}:`, error);
      
      // axios 에러 응답 처리
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.message || '알 수 없는 오류가 발생했습니다.';
        
        // 401 인증 오류 처리
        if (status === 401) {
          $alert({
            title: '인증 오류',
            content: '로그인이 만료되었습니다.',
            error: error
          });
          logout();
          navigate('/home');
          return Promise.reject(error);
        }

        // 그 외 HTTP 에러 처리
        $alert({
          title: `(${status})Error`,
          content: errorMessage,
          error: error
        });
      } else {
        // 네트워크 오류 등 기타 에러 처리
        $alert({
          title: '네트워크 오류',
          content: '서버와 통신 중 문제가 발생했습니다.',
          error: error
        });
      }
      
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        register,
        login,
        tokenLogin,
        socialLogin,
        logout,
        emailIdentityVerificationSendCode,
        emailIdentityCodeVerification,
        isAuthenticated: !!user?.isAuthenticated,
        $navi,
        $home,
        $sessionhome,
        fetchData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;