const icons = {
  'arrow-left': {
    viewBox: '0 0 24 24',
    path: 'M16.1972 22.3825L6 12.1913L16.1972 2L18.1635 3.96625L9.9385 12.1913L18.1635 20.4163L16.1972 22.3825Z'
  },
  'arrow-right': {
    viewBox: '0 0 24 24',
    path: 'm12.6 12-4.6-4.6 1.4-1.4 6 6-6 6-1.4-1.4z'
  },
  'close': {
    viewBox: '0 0 24 24',
    path: 'm6.89984 19.1911-1.59125-1.5913 5.60001-5.6-5.60001-5.59996 1.59125-1.59125 5.59996 5.60001 5.6-5.60001 1.5913 1.59125-5.6 5.59996 5.6 5.6-1.5913 1.5913-5.6-5.6z'
  },
  'appbar-home': {
    viewBox: '0 0 24 24',
    path: 'M3.79675 21.2032V8.89817L12 2.74292L20.2033 8.89617V21.2032H14.0478V13.9522H9.95225V21.2032H3.79675Z'
  },
  'appbar-explore': {
    viewBox: '0 0 24 24',
    path: 'm20.0882 21.2271-6.3418-6.342c-.496.3841-1.068.6889-1.716.9142s-1.3406.338-2.07796.338c-1.85267 0-3.42208-.6431-4.70825-1.9292-1.28617-1.2864-1.92925-2.8558-1.92925-4.7083 0-1.85249.64308-3.42191 1.92925-4.70825 1.28617-1.28616 2.85558-1.92925 4.70825-1.92925 1.85246 0 3.42186.64309 4.70796 1.92925 1.2864 1.28634 1.9295 2.85576 1.9295 4.70825 0 .7413-.1126 1.434-.338 2.078-.2255.644-.5302 1.212-.9142 1.704l6.3477 6.3538zm-10.13576-7.3648c1.21396 0 2.24456-.4236 3.09176-1.2707.8472-.847 1.2707-1.8776 1.2707-3.0918 0-1.21416-.4235-2.24474-1.2707-3.09174-.8472-.84717-1.8778-1.27076-3.09176-1.27076-1.21416 0-2.24483.42359-3.092 1.27076-.847.847-1.2705 1.87758-1.2705 3.09174 0 1.2142.4235 2.2448 1.2705 3.0918.84717.8471 1.87784 1.2707 3.092 1.2707z'
  },
  'appbar-investStatus': {
    viewBox: '0 0 24 24',
    path: 'm21.7524 11.0859v7.9142c0 .6297-.2218 1.1663-.6653 1.6098s-.98.6652-1.6097.6652h-13.90451c-.62966 0-1.16625-.2217-1.60975-.6652s-.66525-.9801-.66525-1.6098v-7.8842c-.38733-.3819-.67816-.8578-.8725-1.42777-.19433-.56984-.1915-1.18376.0085-1.84176l1.05-3.4c.14534-.47316.40675-.86641.78425-1.17975.3775-.31316.81317-.46974 1.307-.46974h13.89996c.4939 0 .9264.15141 1.2975.45424.3712.303.6358.70142.7938 1.19525l1.05 3.4c.2.65.2018 1.25059.0055 1.80175-.1963.55138-.4862 1.03058-.8695 1.43778zm-7.0273-1.15752c.4262 0 .75-.14517.9713-.4355.2213-.2905.307-.61742.257-.98076l-.544-3.44024h-1.8125v3.62825c0 .334.1097.62225.329.86475.2195.24233.4859.3635.7992.3635zm-4.5 0c.3595 0 .6541-.12117.8838-.3635.2297-.2425.3445-.53075.3445-.86475v-3.62825h-1.81251l-.544 3.44024c-.06266.372.01784.70109.2415.98725.22384.286.51942.42901.88671.42901zm-4.44996 0c.284 0 .53159-.10434.74275-.313.211-.20867.3395-.46775.3855-.77725l.538-3.76625h-1.8125l-.982 3.29624c-.096.32134-.04783.66176.1445 1.02126.1925.35933.52042.539.98375.539zm13.49996 0c.4634 0 .7945-.17967.9933-.539.1987-.3595.2437-.69992.135-1.02126l-1.032-3.29624h-1.7625l.538 3.76625c.046.3095.1745.56858.3855.77725.2112.20866.4587.313.7427.313zm-13.70221 9.07172h13.90451v-6.8407c-.0675.0253-.1118.039-.1328.041s-.0441.003-.0695.003c-.4221 0-.808-.07-1.1575-.21-.3496-.14-.6971-.3688-1.0425-.6863-.308.2842-.6536.5046-1.037.6613-.3833.1566-.7876.235-1.213.235-.442 0-.8588-.0784-1.2505-.235-.3916-.1567-.7415-.3771-1.0495-.6613-.2913.2842-.6245.5046-.9995.6613-.375.1566-.7751.235-1.2005.235-.47129 0-.90379-.0784-1.29746-.235-.39366-.1567-.7445-.3771-1.0525-.6613-.366.3342-.72475.5671-1.07625.6988-.35166.1316-.72625.1975-1.12375.1975-.02533 0-.05483-.001-.0885-.003-.03383-.002-.07175-.0157-.11375-.041z'
  },
  'appbar-mymodel': {
    viewBox: '0 0 24 24',
    path: 'm5.29688 21.3051v-16.23322c0-.62967.22174-1.16626.66525-1.60976.4435-.4435.98008-.66524 1.60975-.66524h9.85652c.6296 0 1.1662.22174 1.6097.66524s.6653.98009.6653 1.60976v16.23322l-7.2033-3.0837zm2.275-3.4625 4.92822-2.1142 4.9283 2.1142v-12.77072h-9.85652z'
  },
  'appbar-more': {
    viewBox: '0 0 24 24',
    path: 'm3.29688 18.293v-2.275h18.40652v2.275zm0-5.1555v-2.275h18.40652v2.275zm0-5.15547v-2.275h18.40652v2.275z'
  },
  'alram': {
    viewBox: '0 0 24 24',
    path: 'm3.79688 19.2034v-2.275h1.92824v-6.7968c0-1.43514.42767-2.71514 1.28301-3.83998.85516-1.12483 1.97333-1.85983 3.35447-2.20499v-.65225c0-.45484.1592-.84151.4775-1.16001.3185-.31833.7052-.47749 1.16-.47749.4549 0 .8415.15916 1.16.47749.3184.3185.4775.70517.4775 1.16001v.65225c1.3852.34516 2.5044 1.07916 3.3575 2.20199.8534 1.12284 1.28 2.40384 1.28 3.84298v6.7968h1.9283v2.275zm8.20922 3.0657c-.5696 0-1.0574-.2022-1.4632-.6067-.4057-.4045-.60853-.8909-.60853-1.459h4.13753c0 .57-.2023 1.0567-.6068 1.4602-.4046.4037-.891.6055-1.459.6055zm-4.00598-5.3407h7.99998v-6.7968c0-1.09997-.3916-2.04164-1.175-2.82497-.7833-.78334-1.725-1.175-2.825-1.175s-2.04164.39166-2.82498 1.175c-.78333.78333-1.175 1.725-1.175 2.82497z'
  }
}

export default icons;