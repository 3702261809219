import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AuthProvider from '@/components/auth/AuthProvider';
import PrivateRoute from '@/components/auth/PrivateRoute';
import Login from '@/pages/Sign/Login';

import SignUpSuccess from '@/pages/Sign/SignUpSuccess';
import ForgotPassword from '@/pages/Sign/ForgotPassword';
import NotFound from '@/pages/NotFound';


// test
import Main from '@/pages/Main';
import TermsOfAgreement from '@/pages/Sign/termsOfAgreement';
import DefaultLayout from '@/components/layout/DefaultLayout';
import SignUp from '@/pages/Sign/SignUp';
import CommonViewProvider from '@/pages/Common/CommonViewProvider';
import SocialSignUpSuccess from "@pages/Sign/SocialSignUpSuccess.tsx";
import Games from '@/pages/Games';
import Private from '@/pages/Private';

// 라우트 타입 정의 개선
interface RouteItem {
  path: string;
  element: JSX.Element;
  name: string;
  isAuth?: boolean;
  children?: RouteItem[];
}

// 메인 라우트와 테스트 라우트 분리
const mainRoutes: RouteItem[] = [
  {
    path: "", 
    element: <DefaultLayout />, 
    name: "DefaultLayout", 
    children: [
      { path: "/", element: <Main />, name: "home" },
      { path: "/games", element: <Games />, name: "games" },
      { path: "/private", element: <Private />, name: "private" },
    ]
  },
  { path: "/login", element: <Login />, name: "login" },
  { path: "/signup", element: <SignUp />, name: "signup" },
  { path: "/social-sign-success", element: <SocialSignUpSuccess />, name: "signupSuccess" },
  { path: "/signupSuccess", element: <SignUpSuccess />, name: "signupSuccess" },
  { path: "/forgotPassword", element: <ForgotPassword />, name: "forgotPassword" },
  { path: "/agree", element: <TermsOfAgreement />, name: "termsOfAgreement" },
];

const AppRoutes = () => {
  const renderRoute = (route: RouteItem) => (
    <Route 
      key={route.path} 
      path={route.path} 
      element={route.isAuth ? <PrivateRoute>{route.element}</PrivateRoute> : route.element}
    >
      {route.children?.map(renderRoute)}
    </Route>
  );

  return (
    <CommonViewProvider>
      <BrowserRouter>
        <AuthProvider>
        <Routes>
          {[...mainRoutes].map(renderRoute)}
          <Route path="*" element={<NotFound />} />
        </Routes>
        </AuthProvider>
      </BrowserRouter>
    </CommonViewProvider>
  );
};

export default AppRoutes;