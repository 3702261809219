import React, { useEffect, useState } from 'react';
import { RContext } from '../../context/RContext.ts';
import { EVENT } from '../../types/types.ts';
import styled from 'styled-components';
import opBgBlack from "@/assets/images/op_bg_black.png";

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${opBgBlack}) repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const TimeoutMessage = styled.div`
  color: #ffffff;
  margin-top: 20px;
  font-size: 14px;
`;

interface ProgressProps {
  context?: RContext;
}

const Progress: React.FC<ProgressProps> = ({ context }) => {
  const [timeoutMessage, setTimeoutMessage] = useState('');

  useEffect(() => {
    if (context) {
      context.export($on);
    }

    // 25초 후에 타임아웃 메시지 표시
    const timeoutId = setTimeout(() => {
      setTimeoutMessage('잠시 후 자동으로 닫힙니다...');
    }, 25000);

    return () => clearTimeout(timeoutId);
  }, [context]);

  const $on = (event: EVENT, data: any) => {
    console.log(event, data);
  };

  return (
    <LoadingOverlay>
      <LoadingSpinner />
      {timeoutMessage && <TimeoutMessage>{timeoutMessage}</TimeoutMessage>}
    </LoadingOverlay>
  );
};

export default Progress;

