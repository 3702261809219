import { Config, Styles, Theme } from '../config';
import { SpacingValue } from '../types';

export function getSpacingStyles(
  spacing: number | object | string,
  theme: typeof Theme,
) {
  // 숫자인 경우 - theme.spacing에서 값을 가져옴
  if (typeof spacing === 'number') {
    return { gap: theme.spacing[spacing as keyof typeof theme.spacing] };
  }
  
  // 객체인 경우 - 반응형 스타일 생성
  if (typeof spacing === 'object') {
    const mediaQueries = Object.entries(spacing).map(([breakpoint, value]) => {
      const width = theme.breakpoint[breakpoint as keyof typeof theme.breakpoint];
      const gap = typeof value === 'string' 
        ? value
        : (value as SpacingValue) in theme.spacing 
          ? theme.spacing[value as keyof typeof theme.spacing]
          : `${value}px`;
          
      return `
        @media (min-width: ${width}) {
          gap: ${gap};
        }
      `;
    });
    
    return { [mediaQueries.join('\n')]: '' };
  }
  
  // 문자열인 경우 - 직접 값 사용
  return { gap: spacing };
}