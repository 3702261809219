import Footer from '@/pages/Footer';
import Header from '@/pages/Header';
import styled, { keyframes } from 'styled-components';
import Popups from './Popups/Popups';
import movie1 from '@/assets/images/market/movie/3.mp4';
import g1 from '@/assets/images/market/girl/g1.png';
import g2 from '@/assets/images/market/girl/g2.png';
import g3 from '@/assets/images/market/girl/g3.png';
import g4 from '@/assets/images/market/girl/g4.png';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MainContentStyled = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  
  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }

  .hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    max-width: 1200px;
    margin: 0 auto;
    gap: 3rem;
    
    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 2rem;
    }
  }
  
  .video-container {
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    video {
      width: 100%; 
      display: block;
    }
  }
  
  .conversation-container {
    flex: 1;
    padding: 2rem;
    
    .conversation {
      margin-bottom: 2rem;
    }
    
    .dialog {
      margin-bottom: 1rem;
      padding: 0.5rem;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
      line-height: 1.5;
      font-size: 1.6rem;
      
      &:nth-child(odd) {
        margin-left: 2rem;
        color: #4a4a4a;
      }
      
      &:nth-child(even) {
        margin-right: 2rem;
        color: #676767;
      }
      
      &:nth-child(1) { animation-delay: 0.5s; }
      &:nth-child(2) { animation-delay: 1.5s; }
      &:nth-child(3) { animation-delay: 2.5s; }
      &:nth-child(4) { animation-delay: 3.5s; }
      &:nth-child(5) { animation-delay: 4.5s; }
    }
    
    .tagline {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 3rem;
      text-align: center;
      opacity: 0;
      animation: ${fadeIn} 0.8s forwards;
      animation-delay: 5.5s;
      color: #333;
    }
    
    .subtitle {
      font-size: 1.2rem;
      text-align: center;
      margin-top: 0.5rem;
      opacity: 0;
      animation: ${fadeIn} 0.8s forwards;
      animation-delay: 6s;
      color: #666;
    }
  }
  
  .heroines-section {
    padding: 4rem 2rem;
    text-align: center;
    padding-bottom: 300px;
    h2 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      position: relative;
      display: inline-block;
      
      &:after {
        content: '';
        position: absolute;
        width: 60%;
        height: 3px;
        background-color: #f0f0f0;
        bottom: -10px;
        left: 20%;
      }
    }
    
    .heroines-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem;
    }
    
    .heroine-card {
      width: 280px;
      opacity: 0;
      animation: ${fadeInUp} 0.8s forwards;
      
      &:nth-child(1) { animation-delay: 0.1s; }
      &:nth-child(2) { animation-delay: 0.3s; }
      &:nth-child(3) { animation-delay: 0.5s; }
      &:nth-child(4) { animation-delay: 0.7s; }
      
      .heroine-image {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center 15%;
        margin: 0 auto 1rem;
        border: 4px solid #f0f0f0;
        transition: transform 0.3s ease, border-color 0.3s ease;
        
        &:hover {
          transform: scale(1.05);
          border-color: #d0d0d0;
        }
      }
      
      .heroine-name {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      
      .heroine-description {
        font-size: 1.2rem;
        color: #666;
        line-height: 1.5;
      }
    }
  }
`;

const Games = (props: any) => {
   

    return (
        <>
            <div className={'main-frame'}>
                <div className={'main-container'}>
                    <Header />
                    <div className={'content-body'}>
                        <MainContentStyled>
                            <div className="hero-section">
                                <div className="video-container">
                                    <video src={movie1} autoPlay loop muted />
                                </div>
                                <div className="conversation-container">
                                    <div className="conversation">
                                        <div className="dialog">"우리 여행갔을때 기억나?"</div>
                                        <div className="dialog">"아.. 그때 정말 재미있었는데.."</div>
                                        <div className="dialog">"우리 그때 뭐때문에 그렇게 웃고 그랬는지 기억도 안난다. 그땐 그냥 아무생각없이 즐거웠던거 같아"</div>
                                        <div className="dialog">"야. 우리 이러지 말고 다시한번 뭉쳐볼까?"</div>
                                        <div className="dialog">"... 아 그러면 정말 좋겠다.."</div>
                                    </div>
                                    <div className="tagline">그때 그 시절</div>
                                    <div className="subtitle">여름...</div>
                                </div>
                            </div>
                            
                            <div className="heroines-section">
                                <h2>다양한 히로인을 만나보세요.</h2>
                                <div className="heroines-container">
                                    <div className="heroine-card">
                                        <img src={g1} alt="이선아" className="heroine-image" />
                                        <div className="heroine-name">이선아</div>
                                        <div className="heroine-description">
                                            밝고 활발한 성격으로 모든 사람들에게 친절하다. 
                                            항상 웃는 얼굴로 친구들을 격려하며, 때로는 
                                            엉뚱한 면모로 친구들에게 웃음을 선사한다.
                                        </div>
                                    </div>
                                    <div className="heroine-card">
                                        <img src={g2} alt="최수진" className="heroine-image" />
                                        <div className="heroine-name">최수진</div>
                                        <div className="heroine-description">
                                            차분하고 지적인 성격으로 책을 좋아한다. 
                                            깊은 통찰력으로 친구들의 고민을 해결해주며, 
                                            내면의 강인함을 가지고 있다.
                                        </div>
                                    </div>
                                    <div className="heroine-card">
                                        <img src={g3} alt="김미래" className="heroine-image" />
                                        <div className="heroine-name">김미래</div>
                                        <div className="heroine-description">
                                            예술적 감성이 풍부하고 자유로운 영혼의 소유자. 
                                            음악과 그림에 재능이 있으며, 남들과 다른 
                                            독특한 시각으로 세상을 바라본다.
                                        </div>
                                    </div>
                                    <div className="heroine-card">
                                        <img src={g4} alt="강아름" className="heroine-image" />
                                        <div className="heroine-name">강아름</div>
                                        <div className="heroine-description">
                                            당찬 성격에 리더십이 있어 늘 친구들을 
                                            이끌어 간다. 운동신경이 좋으며 정의감이 
                                            강해 약자를 보호하는 든든한 친구다.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MainContentStyled>
                    </div>
                    <Footer/>
                </div>
                <Popups />
            </div>
        </>
    );
}

export default Games;



