import { Button, Container, Divider, Stack, Typography } from '@/components';
import { Config, Styles, Theme } from '@/components/common/config';
import { ComponentProps } from '@/components/common/types';

interface TermsDetailProps extends ComponentProps {
  title: string;
  memo: string | undefined;
  onClose: () => void;
  onAgree: () => void;
  isVisible: boolean;
  animation?: 'slideLeft' | 'slideRight' | 'fadeIn' | 'fadeOut';
}

const TermsDetail = (props: TermsDetailProps) => {
  const {
    title,
    memo,
    onClose,
    onAgree,
    isVisible,
    animation,
    ...other
  } = props;

  return (
    <>
      <Container
        page
        className='termsDetailContainer'
        sx={{
          backgroundColor: Theme.color.background,
        }}
        {...other}
      >
        <Container>
          <Typography variant='h6'>{title}</Typography>
          <Divider></Divider>
          <div dangerouslySetInnerHTML={{ __html: memo || '' }} />
        </Container>

          <Button
            fullWidth
            size='large'
            onClick={() => {
              onAgree?.();
              onClose?.();
            }}
            sx={{
              height: '4.5rem !important',
            }}
          >
            동의합니다
          </Button>

      </Container>
    </>
  )
}

export default TermsDetail;