import React, { createContext, useContext, useState } from 'react';
import Alert, { DEFAULT_ALERT_PARAM } from '../Popups/Alert';
import { POP_PROPS } from '../Popups/Popups';
import Progress from '../Popups/Progress';
import { useTheme } from '../ThemeContext';


// 타이머 콜백 타입 정의
interface TimerCallbacks {
  tick?: (remainingTime: number) => void;
  complete?: () => void;
}

// 인증 컨텍스트에서 제공할 값들의 타입 정의
export interface CommonViewContextType {
  $alert: (param: DEFAULT_ALERT_PARAM) => void; // 알림 함수
  $timeout: (duration: number, callbacks: TimerCallbacks) => void;
  $showLoading: () => void;  // 추가
  $hideLoading: () => void;  // 추가
}

// 인증 컨텍스트 생성 (초기값은 undefined)
export const CommonViewContext = createContext<CommonViewContextType | undefined>(undefined);

// 인증 컨텍스트를 쉽게 사용할 수 있게 해주는 커스텀 훅
export const useCommonView = () => {
  const context = useContext(CommonViewContext);
  if (!context) {
    throw new Error('useCommonView must be used within a CommonViewProvider');
  }
  return context;
};

const CommonViewProvider = ({ children }: { children: React.ReactNode }) => {
  const {Layout, Popup} = useTheme();
  let priority = 1000;

  const [popups, setPopups] = useState<POP_PROPS[]>([
    { type: 'alert', jsx: (param) => <Alert param={param} />, isShow: false, priority: 0 },
    { type: 'progress', jsx: (param) => <Alert param={param} />, isShow: false, priority: 0 },
  ]);

  // 타이머 함수 추가
  const $timeout = (duration: number, callbacks: TimerCallbacks) => {
    let remainingTime = duration;
    const interval = setInterval(() => {
      remainingTime--;
      
      // tick 콜백 실행
      callbacks.tick?.(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(interval);
        // complete 콜백 실행
        callbacks.complete?.();
      }
    }, 1000);

    // cleanup 함수 반환
    return () => clearInterval(interval);
  };

  // 📌 alert 팝업 표시
  const $alert = (param: DEFAULT_ALERT_PARAM) => {
    const popup = popups.find((p) => p.type === 'alert');
    if (popup) {
      popup.isShow = true;
      popup.priority = priority++;
      popup.props = param;
      popup.jsx = (props) => (
        <Alert 
          param={props} 
          onClose={(closeType) => closePopup('alert', closeType)}
        />
      );
      setPopups([...popups]);
    }
  }

  // 팝업 닫기 함수 수정
  const closePopup = (type: string, closeType: 'ok' | 'cancel' | 'close') => {
    const popup = popups.find((p) => p.type === type);
    if (popup) {
      popup.isShow = false;
      setPopups([...popups]);
    }
  }

  // 활성화된 팝업 JSX 반환
  const getPopupJsx = () => {
    const sortedPopups = [...popups].sort((a, b) => b.priority - a.priority);
    return sortedPopups
      .filter((p) => p.isShow)
      .map((p) => (
          <Popup.Wrap key={p.type}>
            {p.jsx(p.props)}
          </Popup.Wrap>
      ));
  };

  // 로딩 표시 함수 수정
  const $showLoading = () => {
    const popup = popups.find((p) => p.type === 'progress');
    if (popup) {
      popup.isShow = true;
      popup.priority = priority++;
      popup.jsx = () => <Progress />;
      setPopups([...popups]);

      // 30초 후 자동으로 로딩창 닫기
      setTimeout(() => {
        $hideLoading();
      }, 30000);
    }
  };

  // 로딩 숨기기 함수
  const $hideLoading = () => {
    const popup = popups.find((p) => p.type === 'progress');
    if (popup) {
      popup.isShow = false;
      setPopups([...popups]);
    }
  };

  return (
    <CommonViewContext.Provider
      value={{
        $alert,
        $timeout,
        $showLoading,
        $hideLoading,
      }}
    >
      {children}
      <Layout.Popup>
        {getPopupJsx()}
      </Layout.Popup>
    </CommonViewContext.Provider>
  );
};
export default CommonViewProvider;