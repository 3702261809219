import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { IconButtonProps, styledProps } from '../types';

// 사이즈별 스타일 매핑 객체 추가
const SIZE_STYLES = {
  small: { fontSize: '1.25rem', padding: '5px' },
  medium: { fontSize: '1.5rem', padding: '8px' },
  large: { fontSize: '1.75rem', padding: '12px' }
} as const;

const StyledComponent = styled.button<styledProps>`
  ${Styles.IconButton as any}
  ${props => props.$style}
  ${props => props.$sx}
`;

const IconButton = React.memo(React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    children,
    className,
    component: Component = 'button',
    color,
    size = 'medium',
    type = 'button',
    padding,
    loading = false,
    sx,
    ...other
  } = props;

  // 루트 클래스 이름 계산 - 성능 최적화를 위해 메모이제이션
  const rootClassName = React.useMemo(() => {
    return [
      className,
      loading && 'IconButton-disabled'
    ].filter(Boolean).join(' ');
  }, [className, size, loading]);

  // 스타일 객체 계산 - 성능 최적화를 위해 메모이제이션
  const rootStyles = React.useMemo(() => ({
    ...(color && {
      '--icon-button-color': color in Theme.color ? Theme.color[color as keyof typeof Theme.color] : color,
    }),
    ...(size && {
      fontSize: SIZE_STYLES[size as keyof typeof SIZE_STYLES]?.fontSize ?? size,
      padding: padding || SIZE_STYLES[size as keyof typeof SIZE_STYLES]?.padding || '8px',
    }),
    ...(loading && {
      color: 'transparent',
      pointerEvents: 'none' as const
    }),
  }), [color, loading, sx]);

  return (
    <StyledComponent
      as={Component}
      className={rootClassName}
      type={type}
      $style={rootStyles}
      $sx={sx}
      ref={ref}
      {...other}
    >
      {children}
    </StyledComponent>
  );
}));

IconButton.displayName = 'IconButton';

export default IconButton;
