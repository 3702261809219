import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@/components';
import SignTitle from './SignTitle';

const SignUpSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        page
        className='signUpSuccessContainer'
        gutter='md'
        aria-labelledby="signUpSuccessTitle"
        aria-describedby="signUpSuccessDescription"
        sx={{
          justifyContent: 'center',
        }}
      >
        <SignTitle id="signUpSuccessTitle"
          sx={{
            marginTop: '8rem',
          }}
        />

        <Box id="signUpSuccessDescription"
          sx={{
            textAlign: 'center',
            marginTop: '5rem',
          }}
        >
          <Typography variant='h6'>
            가입이 완료되었습니다.
          </Typography>
          <Typography variant='body1' sx={{ marginTop: '1rem' }}>
            가입 시 작성한 이메일로 메일이 발송되었습니다.<br />
            인증이 완료되면 CRB를 이용하실 수 있습니다.
          </Typography>
        </Box>

          <Button
            fullWidth
            size='large'
            onClick={() => {
              navigate('/login');
            }}
            sx={{
              height: '4.5rem !important',
            }}
          >
            로그인 하기
          </Button>
      </Container>
    </>
  )
}
export default SignUpSuccess;
