import React, { useEffect, useState } from 'react';
import withContext, { WITH_CONTEXT_PROPS } from '../../context/RContextAware.tsx';

import { styled } from 'styled-components';
import { useTheme } from '../ThemeContext.tsx';
// import MyComment from './MyComment.tsx';

export interface POP_PROPS extends WITH_CONTEXT_PROPS {
  type: string;
  jsx: (param: any) => JSX.Element;
  isShow: boolean;
  priority: number;
  props?:any;
}

interface VIEW_PROPS extends WITH_CONTEXT_PROPS {
  vprops?: { type?: string; param?: any; };
}


/**
 * 멀티 팝업(여러 팝업이 동시에 뜨는 경우)을 관리하는 컴포넌트
 */
const Popups: React.FC<VIEW_PROPS> = ({ context }) => {
  const {Layout, Popup} = useTheme();
  
  const [popups, setPopups] = useState<POP_PROPS[]>([

  ]);

  const [currentPopupInfo, setCurrentPopupInfo] = useState<{ type: string; param: any }>({
    type: '', param: {},
  });

  let priority = 1000;

  useEffect(() => {
    if (context) {
      context.export(show, hide);
    }
  }, [context]);

  // 팝업 표시
  const show = (type: string, param: any) => {
    const popup = popups.find((p) => p.type === type);
    if (popup) {
      popup.isShow = true;
      popup.priority = priority++;
      popup.props = param;
      setCurrentPopupInfo({ type: type, param });
      setPopups([...popups]);
    }
  };

  // 팝업 숨기기
  const hide = (type: string) => {
    const popup = popups.find((p) => p.type === type);
    if (popup) {
      popup.isShow = false;
      popup.priority = 0;
      setCurrentPopupInfo({ type: '', param: {} });
      setPopups([...popups]);
    }
  };

  // 활성화된 팝업 JSX 반환
  const getPopupJsx = () => {
    const sortedPopups = [...popups].sort((a, b) => b.priority - a.priority);
    return sortedPopups
      .filter((p) => p.isShow)
      .map((p) => (
        <Popup.Wrap key={p.type} style={{zIndex: p.priority}}>
          {p.jsx(p.props)}
        </Popup.Wrap>
      ));
  };

  return (
    <>
    <Layout.Popup>
      {getPopupJsx()}
    </Layout.Popup>
    </>
  );
};

const PopupWrap = styled.div`
  position: fixed;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export default withContext(Popups, 'Popups');