import React, { useEffect } from 'react';
import { RContext, rootContext } from '../../context/RContext.ts';
import withContext from '../../context/RContextAware.tsx';
import { EVENT } from '../../types/types.ts';
import { useTheme } from '../ThemeContext.tsx';

export interface DEFAULT_ALERT_PARAM {
    title?: string;
    content: string;
    subcontent?: string;
    btnleft?: string;
    btnright?: string;
    btnone?: string; // 단일 버튼
    error?: Error;
    callback?: {
        ok?: () => void; // 매개변수 없음
        close?: () => void; // 매개변수 없음
        cancel?: () => void; // 매개변수 없음
    };
}

export const getDefaultAlertParam = (p?:DEFAULT_ALERT_PARAM): DEFAULT_ALERT_PARAM => {
    const defaultParam: DEFAULT_ALERT_PARAM = {
        title: '알림',
        content: '알림 내용이 없습니다.',
        subcontent: undefined,
        btnleft: undefined,
        btnright: undefined,
        btnone: '확인', // 기본값으로 '확인' 설정
        callback: {}
    };
    if(p) Object.assign(defaultParam, p);
    
    // 액션 버튼이 하나도 없는 경우 btnone을 '확인'로 설정
    if (!p?.btnone && !p?.btnleft && !p?.btnright) {
        defaultParam.btnone = '확인';
    }
    
    return defaultParam;
}

export interface AlertProps {
    param: DEFAULT_ALERT_PARAM;
    context?: RContext;
    onClose?: (type: 'ok' | 'cancel' | 'close') => void;
}

const Alert: React.FC<AlertProps> = ({ context, param, onClose }) => {
    const { Popup } = useTheme();
    const defaultParam: DEFAULT_ALERT_PARAM = getDefaultAlertParam(param);
    useEffect(() => {
        if (context) {
            context.export($on);
        }
    }, [context]);

    const $on = (event:EVENT, data: any) => {
        console.log(event, data);
    };

    const ok = () => {
        if (defaultParam.callback?.ok) {
            defaultParam.callback.ok();
        }
        if (onClose) {
            onClose('ok');
        }
    };

    const cancel = () => {
        if (defaultParam.callback?.cancel) {
            defaultParam.callback.cancel();
        }
        if (onClose) {
            onClose('cancel');
        }
    };

    const close = () => {
        if (defaultParam.callback?.close) {
            defaultParam.callback.close();
        }
        if (onClose) {
            onClose('close');
        }
    };

    return (
        <div className="modal-overlay">
            <div className={`alert-container${defaultParam.error ? ' error' : ''}`}>
                <div className="content">
                    <div className="t-16-sb n-0">{defaultParam.title}</div>
                    <div className="b-15-m n-0">{defaultParam.content}</div>
                    {defaultParam.subcontent && (
                        <div className="b-15-m n-0">{defaultParam.subcontent}</div>
                    )}
                    {defaultParam.error && (
                        <div className="error-message b-15-m n-0">
                            {defaultParam.error.message}
                        </div>
                    )}
                </div>
                <div className="popup-actions">
                    {defaultParam.btnone ? (
                        <div className="alert-btn confirm-btn" onClick={ok}>{defaultParam.btnone}</div>
                    ) : (
                        <>
                            {defaultParam.btnleft && (
                                <div className="alert-btn cancel-btn" onClick={cancel}>{defaultParam.btnleft}</div>
                            )}
                            {defaultParam.btnleft && defaultParam.btnright && (
                                <div className="divider"></div>
                            )}
                            {defaultParam.btnright && (
                                <div className="alert-btn confirm-btn" onClick={ok}>{defaultParam.btnright}</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Alert;

