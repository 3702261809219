// 모바일 웹 최적화 (터치 이벤트, 줌 방지 등)
// 기본 여백 및 박스 사이징 초기화
// 폰트 설정 및 안티앨리어싱
// iOS 관련 최적화
// 스크롤바 스타일링
// 기본 버튼, 링크, 입력창 스타일 초기화

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body, #root{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden; /* 필요 시 스크롤 제거 */
      display: flex;
      flex-direction: column;
  }
  html {
    font-size: 62.5%; // 1rem = 10px 설정
    -webkit-text-size-adjust: none; // iOS 폰트 자동확대 방지
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    background-color: #ffffff;
    color: #333333;
    overflow-x: hidden;
    touch-action: manipulation; // 더블탭 줌 방지
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent; // 모바일 터치 하이라이트 제거
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input, textarea {
    -webkit-appearance: none; // iOS 입력창 기본 스타일 제거
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    border-radius: 0; // iOS 입력창 둥근모서리 제거
  }

  /* 스크롤바 스타일링 */
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* iOS 모멘텀 스크롤 활성화 */
  * {
    -webkit-overflow-scrolling: touch;
  }
`;

export default GlobalStyle;
