import React from "react";
import styled from "styled-components";

interface LayoutContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    align?: "center" | "left" | "right"; // children 정렬 방식 (가로 정렬)
    valign?: "top" | "middle" | "bottom"; // children 세로 정렬 방식 (추가)
    width?: string; // 자식 컨테이너 최대 너비
    height?: string; // 자식 컨테이너 최대 높이
    test?: boolean; // border 추가 여부
    scroll?: "auto" | "hidden" | "scroll"; // 추가
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({
                                                             children,
                                                             align = "center",
                                                             valign = "top", // 기본값을 "top"으로 변경
                                                             width = "100%",
                                                             height = "auto",
                                                             test,
                                                             scroll = "auto", // 기본값 설정
                                                             ...props
                                                         }) => {
    return (
        <StyledLayoutContainer align={align} valign={valign} {...props}>
            <InnerContainer test={test} width={width} height={height} scroll={scroll}>
                {children}
            </InnerContainer>
        </StyledLayoutContainer>
    );
};

const StyledLayoutContainer = styled.div<LayoutContainerProps>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: ${({ align }) =>
            align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start"};
    align-items: ${({ valign }) =>
            valign === "middle" ? "center" : valign === "bottom" ? "flex-end" : "flex-start"};
    padding: 1rem;
    margin: 0;
`;

const InnerContainer = styled.div<{ width: string; height: string; test?: boolean; scroll?: string }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    max-width: 100%;
    max-height: 100%;
    overflow: ${({ scroll }) => scroll};
    ${({ test }) => test && "border: 1px solid #f00;"}
`;

export default LayoutContainer;