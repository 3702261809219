import React from 'react';
import styled from 'styled-components';
import { Config, Styles, Theme } from '../config';
import { BoxProps, styledProps } from '../types';

const StyledComponent = styled.div<styledProps>`
  ${props => props.$sx}
`;

const Box = React.memo(React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const {
    component: Component = 'div',
    children,
    sx,
    ...other
  } = props;


  return (
    <StyledComponent
      as={Component}
      $sx={sx}
      ref={ref}
      {...other}
    >
      {children}
    </StyledComponent>
  );
}));

Box.displayName = 'Container';

export default Box;
